import React, { useEffect, useState } from 'react';
import SiteModal from './SiteModal';
import './TermConditionPolicyModal.scss';
import PrimaryButton from './PrimaryButton';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAccountId } from '../../store/reducers/AccountReducer';
import axios from 'axios';
import { AppDefaults, Utils, constants } from '../../helpers';

export default function TermConditionPolicyModal({ callback }) {
  const privacyPolicyUrl = 'https://policies.hanwhavision.cloud/privacy/index.html';
  const termsAndCondtionUrl = 'https://policies.hanwhavision.cloud/terms-of-service/index.html';
  const accountId = useSelector(getAccountId);
  const token = localStorage.getItem('vmsAuthToken');
  const [updateTermServices, setUpdateTermServices] = useState(false);
  const [updatePrivacyPolicy, setUpdatePrivacyPolicy] = useState(false);
  const [updateTermServicesPricyPolicy, setUpdateTermServicesPricyPolicy] =
    useState(false);
  const [policyResponse, setPolicyResponse] = useState('');
  const [termConditionLink, setTermConditionLink] = useState(termsAndCondtionUrl);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState(privacyPolicyUrl);
  const [checkedTOSPP, setCheckedTOSPP] = useState(false);
  const [checkTOS, setCheckTOS] = useState(false);
  const [checkPP, setCheckPP] = useState(false);
  /**
   * USEEFFECT FOR WHEN ACCOUNTID UPDATE WILL CHECK THE POLICY STATUS
   */
  useEffect(() => {
    if (token !== 'undefined' && accountId) {
      getLatestPolicy(true);
    } else if (!token) {
      delete axios.defaults.headers.common['Authorization'];
      getLatestPolicy(false);
    }
  }, [accountId]);

  /**
   * GET POLICY DETAIL AND VERSION DETAIL API
   */

  const getLatestPolicy = async (status) => {
    const path = status ? `${process.env.REACT_APP_API_BASE_URL}partner/accounts/${accountId}/policies` : 
      `${process.env.REACT_APP_API_BASE_URL}partner/policies`
    const response = await axios.get(
      path,
      Utils.requestHeader()
    );

    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setPolicyResponse(responseData.data);
      setTermConditionLink(responseData.data.tosLink);
      setPrivacyPolicyLink(responseData.data.ppLink);
    }
  };

  useEffect(() => {
    if (policyResponse) {
      if (
        policyResponse.tosVersion < policyResponse.tosLatestVersion &&
        policyResponse.ppVersion < policyResponse.ppLatestVersion
      ) {
        setUpdateTermServicesPricyPolicy(true);
        return;
      }
      if (policyResponse.tosVersion < policyResponse.tosLatestVersion) {
        setUpdateTermServices(true);
        return;
      }
      if (policyResponse.ppVersion < policyResponse.ppLatestVersion) {
        setUpdatePrivacyPolicy(true);
        return;
      }
    }
  }, [policyResponse]);

  /**
   * TERM AND CONDITION API
   */

  const agreeTOSConditions = async () => {
    setUpdateTermServices(false);
    const tosResponse = await axios.put(
      `partner/accounts/${accountId}/tos/policies`,
      {},
      Utils.requestHeader()
    );
    if (tosResponse?.data?.meta?.code === 200) {
      setUpdateTermServices(false);
    } else {
      getLatestPolicy();
    }
  };

  /**
   * PRIVACY POLICY API
   */

  const agreePPCondition = async () => {
    setUpdatePrivacyPolicy(false);
    const ppResponse = await axios.put(
      `partner/accounts/${accountId}/pp/policies`,
      {},
      Utils.requestHeader()
    );
    if (ppResponse?.data?.meta?.code === 200) {
      setUpdatePrivacyPolicy(false);
    } else {
      getLatestPolicy();
    }
  };

  /**
   * TERM AND CINDITION AND PRIVACY POLICY API COMBINED
   */

  const agreeTOSPPCondition = async () => {
    setUpdateTermServicesPricyPolicy(false);
    const tosResponse = await axios.put(
      `partner/accounts/${accountId}/tos/policies`,{},
      Utils.requestHeader()
    );
    const ppResponse = await axios.put(
      `partner/accounts/${accountId}/pp/policies`,{},
      Utils.requestHeader()
    );
    if (
      tosResponse?.data?.meta?.code === 200 &&
      ppResponse?.data?.meta?.code === 200
    ) {
      setUpdateTermServicesPricyPolicy(false);
      setCheckedTOSPP(false);
    } else {
      getLatestPolicy();
    }
  };

  const handleCheckbox = (from) => {
    if (from === AppDefaults.FROM_TOS_PP) {
      setCheckedTOSPP(!checkedTOSPP);
    }
    if (from === AppDefaults.FROM_TOS) {
      setCheckTOS(!checkTOS);
    }
    if (from === AppDefaults.FROM_PP) {
      setCheckPP(!checkPP);
    }
  };

  return (
    <div>
      <SiteModal
        showModal={updateTermServices}
        hideModal={() => setUpdateTermServices(false)}
        modalHeader={true}
        size={'md'}
        className="term-condition-policy"
      >
        <div className="update-terms-services">
          <div>
            <div className="title-holder">
              <div className="title">{constants.UPDATE_TERM_OF_SERVICES}</div>
            </div>
            <div className="sub-title m-t-8">
              {constants.TO_CONTINUE_PLEASE_ACKOWLEDGE}{' '}
              <span className="link">
                <a href={termConditionLink} target="_blank" rel="noreferrer">
                  {constants.TERM_OF_SERVICES}
                </a>
              </span>
            </div>
          </div>
          <div className="check-term">
            <div>
              <input
                type="checkbox"
                className="custom-form-check-input"
                onChange={() => handleCheckbox(AppDefaults.FROM_TOS)}
                checked={checkTOS}
              />
            </div>
            <div className="m-l-8">
              {constants.BY_CHECKING_THISBOX}{' '}
              <span className="link">
                <a href={termConditionLink} target="_blank" rel="noreferrer">
                  {constants.TERM_OF_SERVICES}
                </a>
              </span>
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <PrimaryButton
              className="adddevice-btn"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              type="submit"
              onClick={() => {
                agreeTOSConditions();
              }}
              disabled={!checkTOS}
            >
              {constants.TOS_PP_CONTINUE}
            </PrimaryButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <PrimaryButton
              className="adddevice-btn m-t-8"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              type="submit"
              onClick={() => {
                callback();
              }}
            >
              {constants.TOS_PP_CANCEL}
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>

      <SiteModal
        showModal={updatePrivacyPolicy}
        hideModal={() => setUpdatePrivacyPolicy(false)}
        modalHeader={true}
        size={'md'}
        className="update-privacy-policy"
      >
        <div className="update-terms-services">
          <div>
            <div className="title-holder">
              <div className="title">{constants.UPDATE_PRIVACY_POLICY}</div>
            </div>
            <div className="sub-title m-t-8">
              {constants.TO_CONTINUE_PLEASE_ACKOWLEDGE}{' '}
              <span className="link">
                <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                  {constants.PRIVACY_POLICY}
                </a>
              </span>
            </div>
          </div>
          <div className="check-term">
            <div>
              <input
                type="checkbox"
                className="custom-form-check-input"
                onChange={() => handleCheckbox(AppDefaults.FROM_PP)}
                checked={checkPP}
              />
            </div>
            <div className="m-l-8">
              {constants.BY_CHECKING_THISBOX}{' '}
              <span className="link">
                <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                  {constants.PRIVACY_POLICY}
                </a>
              </span>
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <PrimaryButton
              className="adddevice-btn"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              type="submit"
              onClick={() => {
                agreePPCondition();
              }}
              disabled={!checkPP}
            >
              {constants.TOS_PP_CONTINUE}
            </PrimaryButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <PrimaryButton
              className="adddevice-btn m-t-8"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              type="submit"
              onClick={() => {
                callback();
              }}
            >
              {constants.TOS_PP_CANCEL}
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>

      <SiteModal
        showModal={updateTermServicesPricyPolicy}
        hideModal={() => setUpdateTermServicesPricyPolicy(false)}
        modalHeader={true}
        size={'md'}
        className="update-privacy-policy"
      >
        <div className="update-terms-services">
          <div>
            <div className="title-holder">
              <div className="title">
                {constants.UPDATE_TERM_SERVICE_POLICY}
              </div>
            </div>
            <div className="sub-title m-t-8">
              {constants.TO_CONTINUE_PLEASE_ACKOWLEDGE}{' '}
              <span className="link">
                <a href={termConditionLink} target="_blank" rel="noreferrer">
                  {constants.TERM_OF_SERVICES_NODOT}
                </a>
              </span>{' '}
              and{' '}
              <span className="link">
                <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                  {constants.PRIVACY_POLICY}
                </a>
              </span>
            </div>
          </div>
          <div className="check-term">
            <div className="m-t-15-neg">
              <input
                type="checkbox"
                className="custom-form-check-input"
                onChange={() => handleCheckbox(AppDefaults.FROM_TOS_PP)}
                checked={checkedTOSPP}
              />
            </div>
            <div className="m-l-8">
              {constants.BY_CHECKING_THISBOX}{' '}
              <span className="link">
                <a href={termConditionLink} target="_blank" rel="noreferrer">
                  {constants.TERM_OF_SERVICES_NODOT}
                </a>
              </span>{' '}
              and{' '}
              <span className="link">
                <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                  {constants.PRIVACY_POLICY}
                </a>
              </span>
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <PrimaryButton
              className="adddevice-btn"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              type="submit"
              onClick={() => {
                agreeTOSPPCondition();
              }}
              disabled={!checkedTOSPP}
            >
              {constants.TOS_PP_CONTINUE}
            </PrimaryButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <PrimaryButton
              className="adddevice-btn m-t-8"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              height="44px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              type="submit"
              onClick={() => {
                callback();
              }}
            >
              {constants.TOS_PP_CANCEL}
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>
    </div>
  );
}
