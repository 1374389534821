import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cdnInfo: '',
  metadata: '',
  mqttConnection: '',
  platform: null,
  snapshotImage: null,
  localStream: null,
  liveStream: null,
  remoteStream: null,
  isRemoteStreamPlay: false,
  remoteStreamAudio: null,
  deviceStartDate: '',
  loadingStream: false,
  wssConnection: '',
  liveCameraIds: [],
  wssConnections: null,
  rtcPeerConnections: null,
  liveStreams: null,
  snapshotImages: null,
  remoteStreams: null,
  remoteStreamsAudio: null,
  mqttPeerConnections: null,
  isLiveViewEntitlementExist: false,
  isLiveViewEntitlementExpired: false,
};
// TODO: This file needs to be changed to zustand once priorities tasks get finished.
export const streaming = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    setPlatformInfo: (state, action) => {
      state.platform = action.payload;
    },
    resetPlatformInfo: (state) => {
      state.platform = null;
    },
    setCDNInfo: (state, action) => {
      state.cdnInfo = action.payload;
    },
    setStartDate: (state, action) => {
      state.deviceStartDate = action.payload;
    },
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
    setLocalStream: (state, action) => {
      state.localStream = action.payload;
    },
    setLiveStream: (state, action) => {
      state.liveStream = action.payload;
    },
    setRemoteStream: (state, action) => {
      state.remoteStream = action.payload;
    },
    setIsRemoteStreamPlay: (state, action) => {
      state.isRemoteStreamPlay = action.payload;
    },
    setLiveCameraIds: (state, action) => {
      state.liveCameraIds = action.payload;
    },
    setRemoteStreamAudio: (state, action) => {
      state.remoteStreamAudio = action.payload;
    },
    setSnapshotImage: (state, action) => {
      state.snapshotImage = action.payload;
    },
    setMQTTConnection: (state, action) => {
      state.mqttConnection = action.payload;
    },
    setWSSConnection: (state, action) => {
      state.wssConnection = action.payload;
    },
    setLoadingStream: (state, action) => {
      state.loadingStream = action.payload;
    },
    resetStreaming: () => initialState,
    setWSSConnections: (state, action) => {
      state.wssConnections = {
        ...state.wssConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeWSSConnections: (state, action) => {
      const updatedConnections = { ...state.wssConnections };
      delete updatedConnections[action.payload];
      state.wssConnections = updatedConnections;
    },
    clearWSSConnections: (state) => {
      state.wssConnections = null;
    },
    setRTCPeerConnections: (state, action) => {
      state.rtcPeerConnections = {
        ...state.rtcPeerConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeRTCPeerConnections: (state, action) => {
      const updatedConnections = { ...state.rtcPeerConnections };
      delete updatedConnections[action.payload];
      state.rtcPeerConnections = updatedConnections;
    },
    clearRTCPeerConnections: (state) => {
      state.rtcPeerConnections = null;
    },
    setLiveStreams: (state, action) => {
      state.liveStreams = {
        ...state.liveStreams,
        [action.payload.id]: action.payload.stream,
      };
    },
    removeLiveStreams: (state, action) => {
      const updatedStreams = { ...state.liveStreams };
      delete updatedStreams[action.payload];
      state.liveStreams = updatedStreams;
    },
    clearLiveStreams: (state) => {
      state.liveStreams = null;
    },
    // setSnapshotImages: (state, action) => {
    //   state.snapshotImages = {
    //     ...state.snapshotImages,
    //     [action.payload.id]: action.payload.snapshotImage,
    //   };
    // },
    setRemoteStreams: (state, action) => {
      state.remoteStreams = {
        ...state.remoteStreams,
        [action.payload.id]: action.payload.stream,
      };
    },
    setRemoteStreamsAudio: (state, action) => {
      state.remoteStreamsAudio = {
        ...state.remoteStreamsAudio,
        [action.payload.id]: action.payload.audio,
      };
    },
    removeRemoteStreams: (state, action) => {
      const updatedStreams = { ...state.remoteStreams };
      delete updatedStreams[action.payload];
      state.remoteStreams = updatedStreams;
    },
    clearRemoteStreams: (state) => {
      state.remoteStreams = null;
    },
    setMQTTPeerConnections: (state, action) => {
      state.mqttPeerConnections = {
        ...state.mqttPeerConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeMQTTPeerConnections: (state, action) => {
      const updatedConnections = { ...state.mqttPeerConnections };
      delete updatedConnections[action.payload];
      state.mqttPeerConnections = updatedConnections;
    },
    clearMQTTPeerConnections: (state) => {
      state.mqttPeerConnections = null;
    },
    setIsLiveViewEntitlementExist: (state, action) => {
        state.isLiveViewEntitlementExist = action.payload;
    },
    setIsLiveViewEntitlementExpired: (state, action) => {
        state.isLiveViewEntitlementExpired = action.payload;
    },
  },
});

export const {
  setPlatformInfo,
  resetPlatformInfo,
  setCDNInfo,
  setMetaData,
  setLiveStream,
  setLocalStream,
  setRemoteStream,
  setIsRemoteStreamPlay,
  setRemoteStreamAudio,
  setSnapshotImage,
  setMQTTConnection,
  resetStreaming,
  setLoadingStream,
  setStartDate,
  setWSSConnection,
  setLiveCameraIds,
  setWSSConnections,
  removeWSSConnections,
  clearWSSConnections,
  setRTCPeerConnections,
  removeRTCPeerConnections,
  clearRTCPeerConnections,
  setLiveStreams,
  removeLiveStreams,
  clearLiveStreams,
  // setSnapshotImages,
  setRemoteStreams,
  setRemoteStreamsAudio,
  removeRemoteStreams,
  clearRemoteStreams,
  setMQTTPeerConnections,
  removeMQTTPeerConnections,
  clearMQTTPeerConnections,
  setIsLiveViewEntitlementExist,
  setIsLiveViewEntitlementExpired,
} = streaming.actions;
export const getPlatformInfo = (state) => state.streaming.platform;
export const getCDNInfo = (state) => state.streaming.cdnInfo;
export const getMetaData = (state) => state.streaming.metadata;
export const getLocalStream = (state) => state.streaming.localStream;
export const getLiveStream = (state) => state.streaming.liveStream;
export const getRemoteStream = (state) => state.streaming.remoteStream;
export const getIsRemoteStreamPlay = (state) => state.streaming.isRemoteStreamPlay;
export const getRemoteStreamAudio = (state) =>
  state.streaming.remoteStreamAudio;
export const getSnapshotImage = (state) => state.streaming.snapshotImage;
export const getMQTTConnection = (state) => state.streaming.mqttConnection;
export const getWSSConnection = (state) => state.streaming.wssConnection;
export const getStartDate = (state) => state.streaming.deviceStartDate;
export const getLoadingStream = (state) => state.streaming.loadingStream;
export const getLiveCameraIds = (state) => state.streaming.liveCameraIds;
export const getWSSConnections = (state) => state.streaming.wssConnections;
export const getRTCPeerConnections = (state) =>
  state.streaming.rtcPeerConnections;
export const getLiveStreams = (state) => state.streaming.liveStreams;
// export const getSnapshotImages = (state) => state.streaming.snapshotImages;
export const getRemoteStreams = (state) => state.streaming.remoteStreams;
export const getRemoteStreamsAudio = (state) =>
  state.streaming.remoteStreamsAudio;
export const getMQTTPeerConnections = (state) =>
  state.streaming.mqttPeerConnections;
export const getIsLiveViewEntitlementExist = (state) =>
  state.streaming.isLiveViewEntitlementExist;
export const getIsLiveViewEntitlementExpired = (state) =>
  state.streaming.isLiveViewEntitlementExpired;
export default streaming.reducer;
