import { Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BreadcrumbList, Header } from '../../components/common';
import { constants, roles } from '../../helpers';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import PageWrapper from '../PageWrapper';
import { useCallback, useEffect, useState } from 'react';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { setAllDevicesData } from '../../store/AccountStoreIDB';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { observerInstance } from '../../store/indexDB/observer';

function CameraList() {
  const dispatch = useDispatch();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const orgDetails = getCustomerOrgData()[0];
  // dispatch(setAllDevicesData([...orgDetails?.devices]));

  const [orgDetails, setOrgDetails] = useState();

  const breadList = [
    {
      url: '/customers/manage.html',
      title: (loggedInUser.role !== roles.ROLE2PORTAL && loggedInUser.role !== roles.ROLE7PORTAL) ?  constants.MANAGE_ORG_PAGE_TITLE : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgDetails?.orgId}`,
      title: `${orgDetails?.orgName}`,
    },
    { url: '/devices/', title: constants.DEVICES_PAGE_TITLE },
  ];

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
    await setAllDevicesData(orgs?.[0]?.devices || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  return (
    <div className="App">
      <Header breadcrumbData={breadList} />
      <div className="App">
        <PageWrapper>
          <div className="page-header mb-3">
            <Container className="container-top-offset" lg={8}>
              <Col md={6} lg={6} xl={6} xs={12}>
                <BreadcrumbList list={breadList} />
              </Col>
            </Container>
          </div>
          <div>
            {Array.isArray(orgDetails?.devices) &&
              orgDetails?.devices.length > 0 && (
                <>
                  <div>
                    {orgDetails?.devices.map((device, id) => (
                      <div key={id}>
                        <Link
                          to={`/cameras/dashboard.html?id=${device.deviceId}`}
                        >
                          {device.deviceName}
                        </Link>
                      </div>
                    ))}
                  </div>
                </>
              )}
          </div>
        </PageWrapper>
      </div>
    </div>
  );
}
export default CameraList;
