import { useEffect, useRef, useState } from "react";
import { Accordion, Alert, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SelectField, TextField } from "../../../../components/forms";
import { Field, Formik } from "formik";
import { constants, Utils } from "../../../../helpers";
import { HiOutlineExclamationCircle, HiOutlineInformationCircle } from "react-icons/hi";
import { FaCircle } from "react-icons/fa";
import moment from "moment";
import timezones from "../../../../data/support/timezone.json";
import { publishHubDeviceSetting } from "../shared/PublishDeviceSettings";
import "./HubInfoBlock.scss";
import { getLocationsData } from "../../../../store/reducers/AccountReducer";
import { DeviceTypeEnum } from "../../../../helpers/enums";
import { BsInfinity  } from "react-icons/bs";
import { getManufacturerSKUList } from '../../../../store/reducers/NVRDeviceReducer';
import { findTimeZoneFromSelectedValue } from "../../../../helpers/commonUtils";
import LocationSelectField from "../../../../components/forms/LocationSelectField";
import { publishFirmwareDeviceSetting } from "../shared/PublishDeviceSettings";

const HubInfoBlock = ({ deviceId, deviceInfos, saveAudioSettingsFlag, saveAudioSettingsCallback, moveDeviceDetailCallback, updateStore, accountId, uuid, devicePublishId, updateFirmware, sessionId }) => {
	const { t } = useTranslation();
	const formRef = useRef();
	const [variant, setVariant] = useState("danger");
	const [userMsg, setUserMsg] = useState("");
	const [showMsg, setShowMsg] = useState(false);
	const [deviceInfo, setDeviceInfo] = useState(deviceInfos);
	const [deviceName, setDeviceName] = useState(deviceInfos?.deviceName);
	const [selectedAreaLocation, setSelectedAreaLocation] = useState({});
	const zoneTimeList = timezones?.data;
	const timezone = deviceInfos?.properties?.timezone;
	const [currentUserTimezone, setCurrentUserTimezone] = useState(timezone);
	const [currentTime, setCurrentTime] = useState(moment.tz(moment(), timezone ? findTimeZoneFromSelectedValue(timezone)?.location : moment.tz.guess()).format("MMM DD, YYYY hh:mm:ss a"));
	const locationsData = useSelector(getLocationsData);
	const deviceState = deviceInfos?.deviceStatus;
	const connectionState = deviceInfos?.connectionStatus;
	const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
	const resourceList = Utils.getDeviceSettingResource(0);
	const resourceListUpdate = Utils.getDeviceSettingResource(6);
	const neworkProperty = deviceInfos?.properties;
	const manufacturerSKUList = useSelector(getManufacturerSKUList);
	const tid = Math.floor(new Date().getTime() / 1000.0);
	const publishPayload = {
		transactionID: tid,
		accountId: accountId,
		deviceId: deviceId,
		hubId: deviceInfos?.gatewayId,
		uuid: sessionId,
		devicePublishId: devicePublishId,
	};

	useEffect(() => {
    setDeviceInfo(deviceInfos);
    }, [deviceInfos]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
	}, []);

	useEffect(() => {
		if (currentUserTimezone) {
			let secTimer = setInterval(() => {
				setCurrentTime(moment.tz(moment(), findTimeZoneFromSelectedValue(currentUserTimezone)?.location).format("MMM DD, YYYY hh:mm:ss a"));
			}, 1000);
			return () => clearInterval(secTimer);
		}
	}, [currentUserTimezone]);

	useEffect(() => {
		setCurrentUserTimezone(timezone);
		setDeviceName(deviceInfos?.deviceName)
	}, [deviceInfos]);

	useEffect(() => {
		if (formRef?.current && saveAudioSettingsFlag) {
			formRef?.current?.handleSubmit();
		}
	}, [saveAudioSettingsFlag]);

	useEffect(() => {
		moveDeviceLocation(selectedAreaLocation);
	}, [deviceName]);

	const moveDeviceLocation = async (slectedAreaLocation) => {
		try {
			const reqBody = {
				oldLocationId: deviceInfos?.locationId,
				oldAreaId: deviceInfos?.areaId,
				olddeviceName: deviceInfos?.deviceName,
				newLocationId: Object.keys(slectedAreaLocation).length === 0 ? deviceInfos?.locationId : slectedAreaLocation?.locationId,
				newAreaId: Object.keys(slectedAreaLocation).length === 0 ? deviceInfos?.areaId : slectedAreaLocation.areaId,
				deviceName: deviceName ? deviceName : deviceInfos?.deviceName,
				orgId: deviceInfos?.orgId,
				deviceId: deviceInfos?.deviceId,
			};
			moveDeviceDetailCallback(reqBody);
		} catch (error) {
			console.error("ERROR: ", error);
		}
	};

	const onDeviceNameChange = (e, setFieldValue) => {
		setDeviceName(e.target.value);
		setFieldValue("deviceName", e.target.value);
    };

	const onUpdateFirmware = () => {
		const properties = {
			"manual-update": constants.PROPERTY_STATUS_TRUE,
		};
		updateStore(properties);
		publishFirmwareDeviceSetting(properties, resourceListUpdate[2], publishPayload, false);
	};

	const onUpdateDeviceFirmware = () => {
		const properties = {
			"manual-dev-update": constants.PROPERTY_STATUS_TRUE,
		};
		updateStore(properties);
		publishFirmwareDeviceSetting(properties, resourceListUpdate[3], publishPayload, false);
	};

	const avlbleDeviceVersion = deviceInfos?.properties?.['avlbl-device-ver'];
	const firmwareVersion = deviceInfos?.properties['firmware-version'];
	
	return (
		<div className={`text-start audio-settings-block ${curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS ? " editable-off-offline" : ""}`}>
			{showMsg && (
				<Alert variant={variant} onClose={() => setShowMsg(false)} dismissible>
					<Row>
						<Col md={1}>
							<HiOutlineExclamationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue("--error_24")} />
						</Col>
						<Col>
							{!userMsg ? (
								<p>{userMsg}</p>
							) : (
								<>
									<Alert.Heading>{constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}</Alert.Heading>
									<p>{constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}</p>
								</>
							)}
						</Col>
					</Row>
				</Alert>
			)}
			{deviceInfos && (
				<Formik
					enableReinitialize
					innerRef={formRef}
					initialValues={{
						deviceName: deviceInfos?.deviceName,
						volume: 2,
						sensitivity: 2,
						deviceDegrees: "",
						noiseReduction: false,
						audioOutVolume: 2,
						areaData: deviceInfos?.areaId,
						locationData: deviceInfos?.locationId,
						timezone: findTimeZoneFromSelectedValue(currentUserTimezone ? currentUserTimezone : timezone)?.location,
					}}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(true);
						setVariant("danger");
						setUserMsg("");
						setSubmitting(false);
						saveAudioSettingsCallback(false);
					}}>
					{({ values, handleSubmit, setFieldValue, handleChange }) => (
						<Form className="form" onSubmit={handleSubmit}>
							<Row className="settings-block">
								<Col>
									<div className="section-heading mb-2">
										<label>{constants.CHANNELS_INFO_DEVICE_NAME_TITLE}</label>
									</div>
									<TextField required={true} removebottommargin="true" removetopmargin="true" label="" placeholder={constants.DEVICE_INFO_MEETING_ROOM_TITLE} name="deviceName" value={values.deviceName} type="text" onChange={(e) => onDeviceNameChange(e, setFieldValue)} maxLength="40"/>
								</Col>
							</Row>
							<div className="section-heading mb-2 mt-4">
								<label>{constants.CHANNELS_INFO_DEVICE_TITLE}</label>
							</div>
							<div className="device-info-section">
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICE_INFO_DEVICE_STATUS_TITLE}</div>
										<div className="hub-info-block-content">
											{curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS ? <FaCircle size={10} className="status-icon-online" /> : curDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS ? <FaCircle size={10} className="status-icon-offline" /> : <FaCircle size={10} className="status-icon-entered" />}
											<div className="block-content">{curDeviceStatus}</div>
										</div>
									</Col>
								</Row>
								{curDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS && (
									<Alert variant="danger">
										<Row>
											<Col md={1}>
												<HiOutlineInformationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue("--error_24")} />
											</Col>
											<Col>
												{userMsg !== "" ? (
													<p>{userMsg}</p>
												) : (
													<>
														{constants.CHANNELS_INFO_CANNOT_AUTH_DEVICE_TITLE}
														<p>{t("CHANNELS_INFO_CANNOT_AUTH_DEVICE_CONTENT")}</p>
													</>
												)}
											</Col>
										</Row>
									</Alert>
								)}
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_MANUFACTURER_TEXT}</div>
										<div className="hub-info-block-content">{Utils.getManufacturer(deviceInfos?.manufacturer)}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_MODEL_TEXT}</div>
										<div className="hub-info-block-content">
											{deviceInfos.deviceType.toUpperCase() === DeviceTypeEnum.DMSERVER ?
												constants.DEVICES_MODEL_CLOUD_BRIDGE : 
												deviceInfos?.modelNumber
											}
										</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_SERIAL_NO_TEXT}</div>
										<div className="hub-info-block-content">{deviceInfos?.serialNo}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.CHANNELS_INFO_CHANNELS_COUNT_TITLE}</div>
										<div className="hub-info-block-content">
											{deviceInfos?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? 
												manufacturerSKUList?.find((item) => item.model === deviceInfos.modelNumber)?.channelCount || 
												"--" : 
												<span ><BsInfinity  size={16} /></span>
											}
										</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICE_INFO_DEVICES_MAC_ADDRESS_TITLE}</div>
										<div className="hub-info-block-content">{deviceInfos?.macAddress}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">
											{deviceInfos.deviceType.toUpperCase() === DeviceTypeEnum.DMSERVER ? 
											constants.CHANNELS_INFO_DM_BRIDGE_TITLE : constants.CHANNELS_INFO_CAMERA_FIRMWARE_TITLE}
										</div>
										<div className="hub-info-block-content">
											{/* TODO : This static value here -- gonna changed later based on API data */}
											{firmwareVersion ? firmwareVersion : "--"}
										</div>
									</Col>
								</Row>
								{connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS && avlbleDeviceVersion && firmwareVersion?.toLowerCase() !== avlbleDeviceVersion?.toLowerCase() && (
									<Row className="settings-block">
										<Col>
											<div className="alert alert-danger info-update" role="alert">
												<div className="alert-message">
													<HiOutlineExclamationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue("--error_24")} className="alert-icon" />
												</div>
												<p>
													{constants.CHANNELS_INFO_UPDATE_AVAILABLE}{" "}
													<span
														onClick={() => onUpdateDeviceFirmware()}
														style={{
															cursor: "pointer",
															textDecoration: "underline",
															textDecorationColor: getComputedStyle(document.documentElement).getPropertyValue("--error_24"),
														}}>
														{constants.HERE_LABEL}
													</span>
												</p>
											</div>
										</Col>
									</Row>
								)}
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{t("CHANNELS_INFO_FIRMWARE_VERSION_TITLE")}</div>
										<div className="hub-info-block-content">
											{/* TODO : This static value here -- gonna changed later based on API data */}
											{deviceInfos?.properties?.["edge-app-version"] ? deviceInfos?.properties?.["edge-app-version"] : "--"}
										</div>
									</Col>
								</Row>
								{connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS && deviceInfos?.properties?.["avlbl-duclo-ver"] && deviceInfos?.properties?.["edge-app-version"] !== deviceInfos?.properties?.["avlbl-duclo-ver"] && (
									<Row className="settings-block">
										<Col>
											<div className="alert alert-danger info-update" role="alert">
												<div className="alert-message">
													<HiOutlineExclamationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue("--error_24")} className="alert-icon" />
												</div>
												<p>
													{constants.CHANNELS_INFO_UPDATE_AVAILABLE}{" "}
													<span
														onClick={() => onUpdateFirmware()}
														style={{
															cursor: "pointer",
															textDecoration: "underline",
															textDecorationColor: getComputedStyle(document.documentElement).getPropertyValue("--error_24"),
														}}>
														{constants.HERE_LABEL}
													</span>
												</p>
											</div>
										</Col>
									</Row>
								)}
							</div>
							<div className="section-heading mb-2 mt-4">
								<label>{constants.CHANNELS_INFO_NETWORK_TITLE}</label>
							</div>
							<div className="device-info-section">
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_TAB_NETWORK_IP_TYPE_TITLE}</div>
										<div className="hub-info-block-content">{neworkProperty?.["ip-type"] || "--"}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_TAB_NETWORK_IP_ADDRESS_TITLE}</div>
										<div className="hub-info-block-content">{neworkProperty?.["ip-address"] || "--"}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_MAC_ADDRESS_TEXT}</div>
										<div className="hub-info-block-content">{deviceInfos?.macAddress}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_TAB_NETWORK_SUBNET_MASK_TITLE}</div>
										<div className="hub-info-block-content">{neworkProperty?.["subnet-mask"] || "--"}</div>
									</Col>
								</Row>
								<Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_TAB_NETWORK_DNS1_TITLE}</div>
										<div className="hub-info-block-content">{neworkProperty?.["primary-dns"] || "--"}</div>
									</Col>
								</Row>
								{neworkProperty?.["secondary-dns"] && <Row className="settings-block">
									<Col>
										<div className="hub-info-block-heading">{constants.DEVICES_TAB_NETWORK_DNS2_TITLE}</div>
										<div className="hub-info-block-content">{neworkProperty?.["secondary-dns"] || "--"}</div>
									</Col>
								</Row>}
							</div>

							<div className="section-heading mb-2 mt-4">
								<label>{constants.CHANNELS_INFO_LOCATION_TEXT}</label>
							</div>
							<div className="device-info-section">
								<Accordion>
									<Accordion.Item>
										<div className="channel-info radio-wrapper">
											{locationsData?.map((location) => (
												<div className="radiotitle" key={location.locationId}>
													<Col>
														<div
															style={{
																color: getComputedStyle(document.documentElement).getPropertyValue("--greyscale_08"),
															}}>
															{location.locationName}
														</div>
													</Col>
													<Col xs={1} md={1} lg={1} className="makingBig">
														<Field
															type="radio"
															name="locationId"
															value={values.locationData}
															checked={values.locationData === location.locationId}
															onClick={() => {
																const selectedArea = location.areas.find((area) => area.isDefault);
																const selectedLocationArea = {
																	areaId: selectedArea?.areaId || location?.areas?.[0]?.areaId,
																	locationId: location.locationId,
																};
																setFieldValue("locationData", selectedLocationArea?.locationId);
																setFieldValue("areaData", selectedLocationArea?.areaId);
																setSelectedAreaLocation(selectedLocationArea);
																moveDeviceLocation(selectedLocationArea);
															}}
														/>
													</Col>
												</div>
											))}
										</div>
									</Accordion.Item>
								</Accordion>
							</div>
							<Row className="settings-block">
								<Col>
									<div className="section-heading mb-2 mt-4">
										<label>{constants.CHANNELS_INFO_DATE_TIME_TITLE}</label>
									</div>

									<div className="hub-info-block-heading">{constants.DEVICE_INFO_CURRENT_TIME_TITLE}</div>
									<div className="hub-info-block-content time-zone-select">{currentTime}</div>
									<div className="hub-info-block-content device">
										<LocationSelectField
											name="timezone"
											options={zoneTimeList}
											label=""
											value={values.timezone}
											onChange={(event) => {
												let selectedVal = event?.target?.value;
												const getLocationSelected = findTimeZoneFromSelectedValue(selectedVal);
												setCurrentUserTimezone(getLocationSelected.location);
												setFieldValue("timezone", getLocationSelected.label);
												const property = {
													timezone: selectedVal.toString(),
												};
												updateStore(property);
												publishHubDeviceSetting(property, resourceList[1], {
													deviceId: deviceId,
													devicePublishId: devicePublishId,
													hubId: deviceInfos?.gatewayId,
													uuid: uuid,
													accountId: accountId,
												});
											}}
										/>
									</div>
									<div className="label-timezone device-setting">{constants.DEVICE_ADD_TIMEZONE_MESSAGE}</div>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

export default HubInfoBlock;
