import React, { useState, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PrimaryButton, SiteToast, TextBlock } from '../../components/common';
import { SelectField, TextField } from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import ManageAreas from './ManageAreas';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import timezones from '../../data/support/timezone.json';
import { useEffect } from 'react';
import { useOrganizations } from '../../store/OrganizationsStore';
import { PiWarningCircleBold } from 'react-icons/pi';
import { IoClose } from "react-icons/io5";
import LocationSelectField from '../../components/forms/LocationSelectField';
import { findTimeZoneFromSelectedValue } from '../../helpers/commonUtils';
import { useSelector } from 'react-redux';
import { getLoggedInUserRole, getPartnerOrgData } from '../../store/reducers/OrganizationsReducer';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

// Schema for yup
const validationSchema = Yup.object().shape({
  locationName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
});

function NewLocations(props) {
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [newLocaAreas, setNewLocaAreas] = useState([]);
  const childFunc = React.useRef(null);
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  const [orgContact, setOrgContact] = useState([]);
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact
  );
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);

  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const [customerOrgData, setCustomerOrgData] = useState();
  const partnerOrgData = useSelector(getPartnerOrgData);
  const [removeAreaName, setRemoveAreaName] = useState(null);
  const [removeAreaIndex, setRemoveAreaIndex] = useState(null);
  const partnerOrgId =
    (loggedInUserRole === roles.ROLE4PORTAL || loggedInUserRole === roles.ROLE5PORTAL || loggedInUserRole === roles.ROLE6PORTAL)
      ? partnerOrgData?.orgId
      : customerOrgData?.[0]?.orgId;

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgData(orgs);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log('currentUserTimezone', currentUserTimezone);
  }, [currentUserTimezone]);

  useEffect(() => {
    if (partnerOrgId) {
      // get data for organization contact dropdown
      const roleParam = encodeURIComponent(roles.ROLE2PORTAL);
      getOrganizationContact(`/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParam}`)
        .then((res) => {
          setOrgContact(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orgId]);

  const changeTimezoneValue = (event, setFieldValue) => {
    let selectedVal = event?.target?.value;
    const getLocationSelected = findTimeZoneFromSelectedValue(selectedVal);
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('timezone', getLocationSelected?.location);
    setVisibleLocHelperText(false);
  };
  const checkAddedAreas = () => {
    if(newLocaAreas.some((area) => area.isDefault && !area.areaName)){
      return true;
    }
    const nameEmpty = newLocaAreas.filter((area) => !area.areaName);
    if(nameEmpty.length === newLocaAreas.length){
      return true;
    }
    return false;
  }

  const removeIndexAreaName = (index)=>{
    const updatedData = newLocaAreas.filter((_, i) => i !== index);
    setNewLocaAreas(updatedData);
  }
  return (
    <section className="modal-main-content">
      <Formik
        initialValues={{
          locationName: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          locationStatus: 'Active',
          lat: 0,
          lng: 0,
          contactId: '',
          isDefault: false,
          areas: [],
          timezone: currentUserTimezone?.location,
          // bandwidth: ''
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const areaSelected = childFunc.current();
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          setUserMsg('');
          if (orgId !== null && orgId !== undefined) {
            // Simulate submitting to database, shows us values submitted, resets form
            values.areas = areaSelected !== undefined ? areaSelected : [];
            axios
              .post(`partner/orgs/${orgId}/locationAreas`, values, Utils.requestHeader())
              .then((res) => {
                let response = res.data;
                if (response.meta.code === 200) {
                  setSubmitting(false);
                  props.reloadData();
                  props.hideModal();
                } else {
                  setVariant('error');
                  setUserMsg(response?.meta?.userMsg);
                  setSubmitting(false);
                }
              })
              .catch(function (error) {
                setVariant('error');
                setUserMsg(error?.message);
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          isValid,
          dirty,
        }) => (
          <>
            <Form className="form" onSubmit={handleSubmit}>
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.LOCATION_NAME_FIELD}
                name="locationName"
                type="text"
              />

              <div className="row">
                <div className="col position-relative mt-2 address-with-reset">
                  <div>
                  <Autocomplete
                      name="displayDetails"
                      placeholder=""
                      apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                      onChange={(evt) => {
                        if (!evt.target.value) {
                          setFieldValue('zipcode', '');
                          setFieldValue('country', '');
                          setFieldValue('city', '');
                          setFieldValue('state', '');
                          setFieldValue('addressLine1', '');
                        }
                      }}
                      onBlur={handleBlur}
                      onKeyDown={(e) => disableInput(e, values)}
                      // onFocus={
                      //   (e) => e.target.setAttribute("autoComplete", "none")
                      // }
                      id="displayDetails"
                      options={{
                        fields: [
                          'formatted_address',
                          'geometry',
                          'name',
                          'address_components',
                        ],
                        strictBounds: false,
                        types: ['address'],
                      }}
                      onPlaceSelected={(place) => {
                        if(place?.address_components?.length){
                        setFieldValue('lat', place?.geometry?.location?.lat());
                        setFieldValue('lng', place?.geometry?.location?.lng());

                        let addressComponent, addressComponentType, rawzipcode, rawcountry, rawcity, rawstate, rawaddline;
                        for (
                          let i = 0;
                          i < place?.address_components?.length;
                          i++
                        ) {
                          addressComponent = place.address_components[i];
                          let j = 0;
                          addressComponentType = addressComponent.types[j];
                          if (addressComponentType === 'postal_code') {
                            rawzipcode = addressComponent.long_name ? addressComponent.long_name : '';
                          }
                          if (addressComponentType === 'country') {
                            rawcountry = addressComponent.long_name ? addressComponent.short_name : '';
                          }
                          if (addressComponentType === 'administrative_area_level_1') {
                            rawstate = addressComponent.long_name  ? addressComponent.short_name : '';
                          }
                          if (addressComponentType === 'locality') {
                            rawcity = addressComponent.long_name  ? addressComponent.long_name : '';
                          }
                          
                          if (
                            addressComponentType === 'street_number' || 
                            addressComponentType === 'route' || 
                            addressComponentType === 'neighborhood' || 
                            addressComponentType.includes('sublocality')
                          ){
                            rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                          }
                        }
                        setFieldValue('zipcode', rawzipcode);
                        setFieldValue('country', rawcountry);
                        setFieldValue('city', rawcity);
                        setFieldValue('state', rawstate);
                        setFieldValue('addressLine1', rawaddline);
                        setFieldValue('displayDetails', 
                        (rawaddline ? rawaddline : '') + 
                        (rawcity ? rawcity + ', ' : '') + 
                        (rawstate ? rawstate + ', ' : '') + 
                        (rawcountry ? rawcountry + ', ' : '') + 
                        (rawzipcode ? rawzipcode : ''));

                        if(place?.geometry?.location?.lat() && place?.geometry?.location?.lng()){

                          var config = {
                            method: 'get',
                            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                              new Date().getTime() / 1000
                            }&language=es&key=${
                              process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY
                            }`,
                            headers: {},
                          };

                          fetch(config?.url)
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (json) {
                            if (json?.status === 'OK') {
                              const data = findTimeZoneFromSelectedValue(json?.timeZoneId);
                              if (data) {
                                setCurrentUserTimezone(data);
                                setFieldValue('timezone', data?.location);
                                setLocHelperText(
                                  constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                );
                              } else {
                                setLocHelperText(
                                  constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                );
                                setFieldValue('timezone', '');
                              }
                              setVisibleLocHelperText(true);
                            } else {
                              setVisibleLocHelperText(true);
                              setLocHelperText(
                                constants.AUTO_TIMEZONE_FAIL_MESSAGE
                              );
                            }
                          });
                        }
                      }
                      }}
                      className={`form-control shadow-none autoaddress ${
                        touched.displayDetails &&
                        (errors.addressLine1 || !values.displayDetails) &&
                        'is-invalid'
                      } ${values.addressLine1 && 'has-value'}`}
                    />
                    {!document.getElementById("displayDetails")?.value ?
                    <span className={`placeholder-wrapper required`}>
                      {constants.ADDRESS_FIELD}
                    </span> : null }
                    {values.addressLine1 ? 
                      <span className={`reset-address-wrapper`}>
                        <IoClose size={15}
                          onClick={()=> {  
                          setFieldValue('zipcode', '');
                          setFieldValue('country', '');
                          setFieldValue('city', '');
                          setFieldValue('state', '');
                          setFieldValue('addressLine1', '');   
                          setFieldValue('displayDetails', ''); 
                          setFieldValue('timezone', ''); 
                          document.getElementById("displayDetails").value='';
                          setFieldTouched('displayDetails', true, false);
                        }}/>
                      </span> : null 
                    }
                  </div>
                  <ErrorMessage
                    component="div"
                    name="addressLine1"
                    className="error"
                  />
                  {touched.displayDetails &&
                    (!values.addressLine1 ||
                    !values.city || !values.state || !values.country || !values.zipcode) && (
                      <div className="error">
                        {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col position-relative mt-2">
                  <div>
                    <LocationSelectField
                      isFromOrg={true}
                      name="timezone"
                      options={zoneTimeList}
                      label=""
                      defaultOptionLabel="Time Zone"
                      value={values.timezone}
                      onChange={(event) =>
                        changeTimezoneValue(event, setFieldValue)
                      }
                    />
                  </div>
                  {visibleLocHelperText ? (
                    <div className="info-timezone">
                      <PiWarningCircleBold
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--greyscale_56')}
                        size={16}
                      />
                      <div className="message-info">{locHelperText}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <SelectField
                isFromOrg={true}
                name="contactId"
                key="contactId"
                options={orgContact}
                defaultOptionLabel={constants.LOCATION_CONTACT_DROPDOWN_LABEL}
              />

              <ManageAreas
                childFunc={childFunc}
                disabledState={!(dirty && isValid)}
                areasData={newLocaAreas}
                updateAreaData={(areas) => setNewLocaAreas(areas)}
                setRemoveAreaName={setRemoveAreaName}
                setRemoveAreaIndex={(e)=>{removeIndexAreaName(e)}}
                setShowRemoveAreaModal={(e)=>{}}
                removeAreaIndex={removeAreaIndex}
                removeAreaName={removeAreaName}
                howRemoveAreaModal={false}
              />

              {/* TODO: will update once get some backend value <label className="pt-2 mt-4">
                <strong className='location-modal-heading'>{constants.BANDWIDTH_USAGE_BUDGET}</strong>

                <TextBlock
                  fontWeight="400"
                  fontSize="0.875rem"
                  lineHeight="20px"
                  color={getComputedStyle(document.documentElement).getPropertyValue('--brand_primary')}
                >
                  {constants.BANDWIDTH_HELPER_TEXT}
                </TextBlock>
              </label> */}

              {/* TODO: will update once get some backend value <div>
                <div className="d-flex align-items-center flex-row mt-3">
                  <input
                    className="form-control require shadow-none"
                    placeholder='(Optional)'
                    autoComplete="off"
                    name="bandwidth"
                    type="text"
                    value={values.bandwidth}
                    onChange={(event) => setFieldValue('bandwidth', event.target.value)}
                  />
                  <TextBlock
                    className="ml-2"
                    fontWeight="500"
                    fontSize="1rem"
                    lineHeight="20px"
                    color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')}
                  >
                    kbps
                  </TextBlock>
                </div>
                <div className="info-timezone mt-1">
                  <div className="message-info">
                    {constants.BANDWIDTH_MAX_CONTENT_HELPER_TEXT}
                  </div>
                </div>
              </div> */}

              <Form.Group as={Row} className="mb-3 mt-5">
                <Col className="text-end">
                  {/* <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="auto"
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    backgroundColor="transparent"
                    disabledBackgroundColor="transparent"
                    disabled={true}
                  >
                    {constants.NEW_LOCATIONS_REMOVE_BUTTON_TITLE}
                  </PrimaryButton> */}
                  <PrimaryButton
                    className="ms-2 btn btn-primary"
                    type="submit"
                    disabled={
                        !(dirty && isValid) ||
                        newLocaAreas?.length === 0 ||
                        currentUserTimezone === undefined ||
                        !document.getElementById("displayDetails")?.value ||
                        checkAddedAreas()
                    }
                    loader={isSubmitting}
                    height="44px"
                    width="auto"
                    fontSize="0.875rem"
                  >
                    {constants.NEW_LOCATIONS_SUBMIT_BUTTON_TITLE}
                  </PrimaryButton>
                </Col>
              </Form.Group>
            </Form>

            {/* Show error messages */}
            <SiteToast
              title={
                variant === 'error'
                  ? constants.ERROR_TOAST_TITLE
                  : constants.SUCCESS_TOAST_TITLE
              }
              show={!!userMsg}
              body={userMsg}
              variant={variant}
              position="top-center"
            />
          </>
        )}
      </Formik>
    </section>
  );
}

export default NewLocations;
