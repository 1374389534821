import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { constants, Utils } from "../../../helpers";
import { useLoggedInUserData } from "../../../store/LoggedInAccountStore";
import { devicesMQTTStore } from "../../../store/DevicesMQTTStore";
import { mqttPublish, mqttSubscribe } from "../../../utils/connection/mqttConnection";

import { FiCameraOff } from "react-icons/fi";
import FakeSnapshot from "../../../assets/images/cameras/snapshots/fake-snapshot.png";
import colors from "../../../assets/css/_colors.scss";
import "./LatestSnapshot.scss";
import { DefaultDevice } from "../../../assets/images";

const LatestSnapshot = ({ orgId, cdnInfo, deviceId, deviceElemId, deviceName, locationAreaName = "", imageURL = FakeSnapshot, currentLayoutStatus = false, clickHandler = null, conStatus, hubId, defaultImage, ...props }) => {
	const [appTopic, setAppTopic] = useState(`a/notify/${hubId}`);
	const [latestSnapshots, setLatestSnapshots] = useState({});
	const [snapshotURL, setSnapshotURL] = useState(imageURL);

	const { getState, subscribe } = devicesMQTTStore;
	const state = getState();
	const loggedInUserData = useLoggedInUserData((state) => state.loggedInUserData);

	const accountId = loggedInUserData.accountId;

	useEffect(() => {
		if (state.getAccountId() !== accountId) {
			state.setAccountId(accountId);
		}

		if (!state.getSessionId()) {
			state.setSessionId(uuidv4());
		}

		const deviceSubscription = {
			topic: `d/notify/${accountId}/${state.getSessionId()}`,
			qos: 0,
		};

		const eventsSubscription = {
			topic: `d/events/${deviceId}`,
			qos: 0,
		};

		// Subscribe to the app topic
		//mqttSubscribe(appSubscription);
		state.setIsPublisherSubscribed(true);

		// Subscribe  to the device topic
		mqttSubscribe(deviceSubscription);
		// mqttSubscribe(eventsSubscription);
		state.setIsReceiverSubscribed(true);

		subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));
		// publishSnapshotRequest();
		// Retrieve latest snapshot every 10 seconds
		// ToDo: Uncomment later for latest snapshot functionality
		// const interval = setInterval(() => {
		// 	publishSnapshotRequest();
		// }, 10000);

		// return () => {
		// 	clearInterval(interval);
		// };
	}, []);

	useEffect(() => {
		const fetchLatestSnapshot = async (lastSnapshot) => {
			if (!lastSnapshot || !cdnInfo) {
				return;
			}

			let bucket = cdnInfo?.bucket?.replace("${deviceId}", deviceId);

			const snapshotImage = new Image();

			// Assign the valid URL to the snapshot URL state
			// variable
			snapshotImage.onload = () => {
				setSnapshotURL(snapshotImage.src);
			};

			// Handle the image loading error (e.g. 404 or 403 error)
			snapshotImage.onerror = () => {
				setSnapshotURL(imageURL);
			};
			const date = Utils.fetchDateInUnix(lastSnapshot);
			snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
		};
		const lastSnapshot = state.latestSnapshots[deviceId];
		const lastEvent = state.latestEvents[deviceId];
		const deviceSnapshot = lastEvent || lastSnapshot;

		fetchLatestSnapshot(deviceSnapshot);
	}, [latestSnapshots]);

	const publishSnapshotRequest = useCallback(() => {
		const tid = Math.floor(new Date().getTime() / 1000.0);
		const sessionId = state.getSessionId();

		if (!accountId) {
			return;
		}

		// Send the request
		const context = {
			topic: appTopic,
			payload: JSON.stringify({
				tid: `${tid}`,
				to: `${hubId}`,
				from: `${accountId}`,
				msg: {
					action: "get",
					resource: `ch/${deviceId}/camera/last-snap-timestamp`,
					publish: `d/notify/${accountId}/${sessionId}`,
				},
			}),
			qos: 0,
		};
		mqttPublish(context);
	}, []);

	return (
		<div key={deviceId} id={deviceElemId} className={`cam cam-1x`}>
			<div className="cam-content">
				{/* {conStatus !== "offline" ? (
					<div className={`cam-snapshot`}>
						<picture className="cam-snapshot-wrapper">
							<img src={snapshotURL} className={`cam-snapshot-image`} alt={`Snapshot of ${deviceName}`} />
						</picture>
					</div>
				) : (
					<div className={`cam-offline`}>
						<div className="cam-offline-content">
							<div className="camera-offline-icon">
								<FiCameraOff stroke={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")} size={22} />
							</div>
							<div className="camera-offline-label">{constants.CAMERAS_VIDEO_WALL_CAMERA_OFFLINE_TITLE}</div>
						</div>
					</div>
				)} */}
				<div className={`cam-snapshot`}>
					<picture className="cam-snapshot-wrapper">
							<img src={defaultImage} onError={(e) => e.target.src = `${DefaultDevice}`} id={`snapshot${deviceId}`} className={`cam-snapshot-image`} alt={`Snapshot of ${deviceName}`} />
					</picture>
				</div>
			</div>
		</div>
	);
};

export default LatestSnapshot;
