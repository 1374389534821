import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Utils } from '../helpers';
import { deleteItem, getItem, setItem } from './indexDB/db';

export const getCustomerOrgDevices = async () => {
  const result = await getItem('customerOrgDevices');
  return result || [];
};

let cancelToken;
export const fetchCustomerOrgDevices = async (route) => {
  if (!route) return;

  // Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  // Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();

  try {
    const requestId = uuidv4();
    // Get location and areas first
    const res = await axios.get(route, {
      params: {
        requestTime: Date.now(),
        requestId: requestId,
      },
      ...Utils.requestHeader(requestId),
      timeout: 60000,
      credentials: 'include',
      withCredentials: true,
      cancelToken: cancelToken.token,
    });
    const response = res?.data;
    let result;

    // Set edit customer org devices if request is successful
    if (response?.meta?.code === 200) {
      let devicesData = response?.data;
      if (Array.isArray(devicesData)) {
        let deviceList = [...devicesData];
        const existingDevices = await getCustomerOrgDevices();
        let newDeviceList = deviceList?.map(async (device, index) => {
          // const olddeviceData = customerOrgDevices?.filter(
          const olddeviceData = existingDevices?.filter(
            (dvc) => dvc.deviceId === device.deviceId
          );
          if (device?.capability) {
            if (
              device?.capability?.version !==
              olddeviceData[0]?.capability?.version
            ) {
              let response = await fetch(device?.capability?.url);
              if (response.status === 200) {
                let responseJson = await response.json();
                responseJson['version'] = device?.capability?.version;
                return { ...device, capDetails: responseJson };
              } else {
                return { ...device };
              }
            } else {
              return {
                ...device,
                capDetails: olddeviceData[0]?.capDetails,
              };
            }
          } else {
            return { ...device };
          }
        });
        let deviceCapability = await Promise.all(newDeviceList);
        await setCustomerOrgDevices(deviceCapability);
      }

      result = {
        status: 'success',
        msg: 'get customer organizations -> devices data.',
      };
    } else {
      if (res?.code) {
        result = {
          status: 'failure',
          msg: `${res.code}: ${res.message}`,
        };
      } else if (response?.data) {
        result = { status: 'failure', msg: response?.data?.userMsg };
      } else {
        result = { status: 'failure', msg: 'Unknown internal API error' };
      }
    }
    return result;
  } catch (err) {
    Utils.vmsLogger().error(err);
  }
};

export const setCustomerOrgDevices = async (devices) => {
  return await setItem('customerOrgDevices', devices);
};

export const setSelectedDeviceFromFillter = async (devices) => {
  return await setItem('commonDevicesfillter', devices);
};

export const getSelectedDeviceFromFillter = async (devices) => {
  return await getItem('commonDevicesfillter');
};

export const deleteCustomerOrgDevices = async (key) => {
  return await deleteItem(key);
};

