import { Col, Row } from "react-bootstrap";
import { constants, DeviceTypeEnum, Utils } from "../../../../helpers";
import "./HubInfoBlock.scss";
import { ToggleInput, TextField } from "../../../../components/forms";
import { useState, useEffect, useRef } from "react";
import timezones from "../../../../data/support/timezone.json";
import moment from "moment";
import { PrimaryButton, SiteModal, TextBlock } from "../../../../components/common";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { publishFirmwareDeviceSetting } from "../shared/PublishDeviceSettings";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { HiExclamationCircle, HiOutlineExclamationCircle } from "react-icons/hi";
import axios from "axios";
import useLicensesStore from "../../../../store/LicensesStore";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/Info-circle.svg';
import { RxCross1 } from 'react-icons/rx';
import { usePoliciesStore } from "../../../../store/policiesStore";
import { getAllMqttDataFromResponse } from '../../../../store/reducers/AccountReducer';
import { getCurrentEventResource } from '../../../../utils/connection/mqttConnection';
import { useSelector } from 'react-redux';
import { findTimeZoneFromSelectedValue } from "../../../../helpers/commonUtils";

const HubNetworkBlock = ({
  orgId,
  orgName,
  deviceDetails,
  deviceId,
  deviceInfos,
  updateStore,
  saveDeviceNetworkSettingsCallback,
  saveDeviceNetworkSettingsFlag,
  accountId,
  sessionId,
  devicePublishId,
  uuid,
}) => {	
  const navigate = useNavigate();
	const { t } = useTranslation();
	const diffrenceTime = 7200000;
	const deviceState = deviceInfos?.deviceStatus;
	const connectionState = deviceInfos?.connectionStatus;
	const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
	const resourceList = Utils.getDeviceSettingResource(6);
    const [userMsg, setUserMsg] = useState('');

	const [daysList, setDaysList] = useState(null);
	const [selectedIds, setSelectedIds] = useState(null);
	const [selectDay, setselectDay] = useState(null);
	const [updateDayStaus, setUpdateDayStaus] = useState(false);

	const [deviceDaysList, setDeviceDaysList] = useState(null);
	const [selectedDeviceIds, setSelectedDeviceIds] = useState(null);
	const [selectDeviceDay, setSelectDeviceDay] = useState(null);
	const [updateDeviceDayStaus, setUpdateDeviceDayStaus] = useState(false);
	const tid = Math.floor(new Date().getTime() / 1000.0);
	const zoneTimeList = timezones?.data;
	const timezone = deviceInfos?.properties?.timezone;
	const cDate = moment.tz(moment(), timezone ? findTimeZoneFromSelectedValue(timezone)?.location : moment.tz.guess()).format("MMM DD, YYYY");
	const [startTime, setStartTime] = useState(new Date(cDate + " " + (deviceInfos?.properties?.["fw-updt-st"] ? Utils.getHoursFromValue(deviceInfos?.properties?.["fw-updt-st"]) : "00:00:00")).getTime());
	const [endTime, setEndTime] = useState(startTime + diffrenceTime);
	const [deviceStartTime, setDeviceStartTime] = useState(new Date(cDate + " " + (deviceInfos?.properties?.["fw-updt-dev-st"] ? Utils.getHoursFromValue(deviceInfos?.properties?.["fw-updt-dev-st"]) : "00:00:00")).getTime());
	const [deviceEndTime, setDeviceEndTime] = useState(deviceStartTime + diffrenceTime);
	const formRef = useRef();
	const [autoUpdateEnable, setAutoUpdateEnable] = useState(deviceInfos?.properties?.["fw-updt-type"] !== constants.UPDATE_FIRMWARE_MANUAL);
	const [autoUpdateDeviceEnable, setAutoUpdateDeviceEnable] = useState(
    deviceInfos?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER
      ? false
      : deviceInfos?.properties?.["fw-updt-dev-type"] !==
          constants.UPDATE_FIRMWARE_DEVICE_MANUAL
  );
	const [deviceRestartModal, setDeviceRestartModal] = useState(false);
	const [deviceFactoryResetModal, setDeviceFactoryResetModal] = useState(false);
	const [deviceReclaimModal, setDeviceReclaimModal] = useState(false);
	const deviceFactoryReset = deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS || (deviceState === constants.DEVICES_CLAIMED_DEVICE_STATUS && connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS);
	const { getLicenses } = useLicensesStore();
	const [licenceAttachedModal, setLicenceAttachedModal] = useState(false);
	const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
	const [showDownloadLogsModal,setShowDownloadLogsModal] = useState(false);
	const [licenseattachedStatus, setLicenseattachedStatus] = useState(false);
	const [encryptionStatus, setEncryptionStatus] = useState(true);  
	const [encriptionKey, setEncriptionKey] = useState('');
	const [downloadStatus,setDownloadStatus] = useState(false);
	const [logsData, setLogsData] = useState(false);  
	const allMqttData = useSelector(getAllMqttDataFromResponse);
	const timerRef = useRef(null);
    const [reclaimError, setReclaimError] = useState("");
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [removeButtonDisableFlag, setRemoveButtonDisableFlag] =
      useState(true);

	const getCustomerOrgPolicies = usePoliciesStore(
		(state) => state.getCustomerOrgPolicies
	);
	const capabilityList = deviceInfos?.capDetails?.capabilities;
	const isCloudCapabilities = capabilityList?.find((capData) => capData?.resource === "camera/diag/duclo-fw-update");
	const isDeviceCapabilities = capabilityList?.find((capData) => capData?.resource === "camera/diag/device-fw-update");

	const selectedDay = (dayData) => {
		const dayListfirm = [...daysList];
		dayListfirm.forEach((data, i) => {
			if (dayData.id === data.id) {
				if (data.isSelected && selectedIds?.length !== 1) {
					daysList[i].isSelected = false;
				} else {
					daysList[i].isSelected = true;
				}
			}
		});
		setDaysList(dayListfirm);
		setUpdateDayStaus(true);
	};

	const selectedDeviceDay = (dayData) => {
		const dayListfirm = [...deviceDaysList];
		dayListfirm.forEach((data, i) => {
			if (dayData.id === data.id) {
				if (data.isSelected && selectedDeviceIds?.length !== 1) {
					deviceDaysList[i].isSelected = false;
				} else {
					deviceDaysList[i].isSelected = true;
				}
			}
		});
		setDeviceDaysList(dayListfirm);
		setUpdateDeviceDayStaus(true);
	};

	useEffect(() => {
		const dayIds = [];
		const defaultSelectedValue = [];
		daysList &&
			daysList.forEach((days) => {
				if (days.isSelected) {
					dayIds.push(days.id);
					defaultSelectedValue.push(days.shortName);
				}
			});
		if (dayIds?.length > 1 && dayIds?.length < 7) {
			setselectDay(constants.UPDATE_FIRMWARE_MULTIPLE);
		} else if (dayIds?.length === 7) {
			setselectDay(constants.UPDATE_FIRMWARE_ALL_DAYS);
		} else if (dayIds?.length === 1) {
			const day = dayIds?.length === 1 ? Utils.getWeekDays(dayIds)?.find((day) => day?.id === dayIds[0])?.day : "Sunday";
			setselectDay(day);
		}
		setSelectedIds(dayIds);
		setUpdateDayStaus(false);
		const properties = {
			"fw-updt-day": dayIds?.join(","),
		};
		updateDayStaus && publishFirmwareDeviceSetting(properties, resourceList[2], publishPayload, false);
	}, [updateDayStaus]);

	useEffect(() => {
		const dayIds = [];
		const defaultSelectedValue = [];
		deviceDaysList &&
			deviceDaysList.forEach((days) => {
				if (days.isSelected) {
					dayIds.push(days.id);
					defaultSelectedValue.push(days.shortName);
				}
			});
		if (dayIds?.length > 1 && dayIds?.length < 7) {
			setSelectDeviceDay(constants.UPDATE_FIRMWARE_MULTIPLE);
		} else if (dayIds?.length === 7) {
			setSelectDeviceDay(constants.UPDATE_FIRMWARE_ALL_DAYS);
		} else if (dayIds?.length === 1) {
			const day = dayIds?.length === 1 ? Utils.getWeekDays(dayIds)?.find((day) => day?.id === dayIds[0])?.day : "Sunday";
			setSelectDeviceDay(day);
		}
		setSelectedDeviceIds(dayIds);
		setUpdateDeviceDayStaus(false);
		const properties = {
			"fw-updt-dev-day": dayIds?.join(","),
		};
		updateDeviceDayStaus && publishFirmwareDeviceSetting(properties, resourceList[3], publishPayload, false);
	}, [updateDeviceDayStaus]);

	useEffect(() => {
		fetchDeviceData();
	}, [deviceId]);

	useEffect(() => {
		if(deviceInfo?.properties){
			const dayIds = deviceInfo?.properties?.["fw-updt-day"];
			setDaysList(Utils.getWeekDays(dayIds ? dayIds.split(",") : ["1"]));
			const selectedDayIds = dayIds ? dayIds.split(",") : ["1"];
			setSelectedIds(selectedDayIds);
			const dayDefault = selectedDayIds?.length > 1 && selectedDayIds?.length < 7 ? constants.UPDATE_FIRMWARE_MULTIPLE : selectedDayIds?.length === 7 ? constants.UPDATE_FIRMWARE_ALL_DAYS : selectedDayIds?.length === 1 ? Utils.getWeekDays(selectedDayIds)?.find((day) => day?.id?.toString() === selectedDayIds[0])?.day : "Sunday";
			setselectDay(dayDefault);
	
			const devcieDayIds = deviceInfo?.properties?.["fw-updt-dev-day"];
			setDeviceDaysList(Utils.getWeekDays(devcieDayIds ? devcieDayIds.split(",") : ["1"]));
			const selectedDeviceDayIds = devcieDayIds ? devcieDayIds.split(",") : ["1"];
			setSelectedDeviceIds(selectedDeviceDayIds);
			const deviceDayDefault = selectedDeviceDayIds?.length > 1 && selectedDeviceDayIds?.length < 7 ? constants.UPDATE_FIRMWARE_MULTIPLE : selectedDeviceDayIds?.length === 7 ? constants.UPDATE_FIRMWARE_ALL_DAYS : selectedDeviceDayIds?.length === 1 ? Utils.getWeekDays(selectedDeviceDayIds)?.find((day) => day?.id?.toString() === selectedDeviceDayIds[0])?.day : "Sunday";
			setSelectDeviceDay(deviceDayDefault);
	
			const sTime = (new Date(cDate + " " + (deviceInfo?.properties?.["fw-updt-st"] ? Utils.getHoursFromValue(deviceInfos?.properties?.["fw-updt-st"]) : "00:00:00")).getTime());
			setStartTime(sTime);
			setEndTime(sTime + diffrenceTime);
			const deviceSTime = (new Date(cDate + " " + (deviceInfo?.properties?.["fw-updt-dev-st"] ? Utils.getHoursFromValue(deviceInfos?.properties?.["fw-updt-dev-st"]) : "00:00:00")).getTime());
			setDeviceStartTime(deviceSTime);
			setDeviceEndTime(deviceSTime + diffrenceTime);
			setAutoUpdateEnable(deviceInfo?.properties?.["fw-updt-type"] && deviceInfo?.properties?.["fw-updt-type"] !== constants.UPDATE_FIRMWARE_MANUAL);
			setAutoUpdateDeviceEnable(
				deviceInfo?.deviceType?.toUpperCase() === DeviceTypeEnum.DMSERVER
					? false
					: deviceInfo?.properties?.["fw-updt-dev-type"] && 
					 deviceInfo?.properties?.["fw-updt-dev-type"] !==
						constants.UPDATE_FIRMWARE_DEVICE_MANUAL);
		}
	}, [deviceInfo]);

	const fetchDeviceData = async () => {
    axios
      .get(`device/orgs/${orgId}/devices/${deviceId}/v2`, Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;

          let responseAppValue = responseData?.apps;
          if (!responseAppValue || Object.keys(responseAppValue).length === 0) {
            setRemoveButtonDisableFlag(false);
          } else {
            let deviceAppsAnyValueTrue = Object.values(responseAppValue).some(
              (value) => value === true
            );
            if (deviceAppsAnyValueTrue) {
              setRemoveButtonDisableFlag(true);
            } else {
              setRemoveButtonDisableFlag(false);
            }
          }

          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === "200")
          ) {
            setDeviceInfo(responseData);
          }
        }
      });
  	};

	const publishPayload = {
		transactionID: tid,
		accountId: accountId,
		deviceId: deviceId,
		hubId: deviceInfos?.gatewayId,
		uuid: sessionId,
		devicePublishId: devicePublishId,
	};

	const setStartEndTime = (epochValue, isTimeStart) => {
		if (isNaN(epochValue)) return;
		if (isTimeStart) {
			setStartTime(epochValue);
			setEndTime(epochValue + diffrenceTime);
			const status = {
				"fw-updt-st": Utils.getStartEndMin(moment(epochValue).format("DD MMM YYYY HH:mm"), true).toString(),
				"fw-updt-et": Utils.getStartEndMin(moment(epochValue + diffrenceTime).format("DD MMM YYYY HH:mm"), false).toString(),
			};
			updateStore(status);
			publishFirmwareDeviceSetting(status, resourceList[2], publishPayload);
		} else {
			setEndTime(epochValue);
			setStartTime(epochValue - diffrenceTime);
			const status = {
				"fw-updt-st": Utils.getStartEndMin(moment(epochValue - diffrenceTime).format("DD MMM YYYY HH:mm"), true).toString(),
				"fw-updt-et": Utils.getStartEndMin(moment(epochValue).format("DD MMM YYYY HH:mm"), false).toString(),
			};
			updateStore(status);
			publishFirmwareDeviceSetting(status, resourceList[2], publishPayload);
		}
	};

	const setDeviceStartEndTime = (epochValue, isTimeStart) => {
		if (isNaN(epochValue)) return;
		if (isTimeStart) {
			setDeviceStartTime(epochValue);
			setDeviceEndTime(epochValue + diffrenceTime);
			const status = {
				"fw-updt-dev-st": Utils.getStartEndMin(moment(epochValue).format("DD MMM YYYY HH:mm"), true).toString(),
				"fw-updt-dev-et": Utils.getStartEndMin(moment(epochValue + diffrenceTime).format("DD MMM YYYY HH:mm"), false).toString(),
			};
			updateStore(status);
			publishFirmwareDeviceSetting(status, resourceList[3], publishPayload);
		} else {
			setDeviceEndTime(epochValue);
			setDeviceStartTime(epochValue - diffrenceTime);
			const status = {
				"fw-updt-dev-st": Utils.getStartEndMin(moment(epochValue - diffrenceTime).format("DD MMM YYYY HH:mm"), true).toString(),
				"fw-updt-dev-et": Utils.getStartEndMin(moment(epochValue).format("DD MMM YYYY HH:mm"), false).toString(),
			};
			updateStore(status);
			publishFirmwareDeviceSetting(status, resourceList[3], publishPayload);
		}
	};

	useEffect(() => {
		if (formRef?.current && saveDeviceNetworkSettingsFlag) {
			formRef?.current?.handleSubmit();
		}
	}, [saveDeviceNetworkSettingsFlag]);

	const renderTimePicker = (timeValue, isStart) => {
		return (
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DesktopTimePicker
					onChange={(newValue) => {
						setStartEndTime(newValue, isStart);
					}}
					renderInput={(params) => <TextField />}
					defaultValue={moment(timeValue)}
					value={moment(timeValue)}
				/>
			</LocalizationProvider>
		);
	};

	const renderDeviceTimePicker = (timeValue, isStart) => {
		return (
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DesktopTimePicker
					onChange={(newValue) => {
						setDeviceStartEndTime(newValue, isStart);
					}}
					renderInput={(params) => <TextField />}
					defaultValue={moment(timeValue)}
					value={moment(timeValue)}
				/>
			</LocalizationProvider>
		);
	};

	const onUpdateFirmware = () => {
		const properties = {
			"manual-update": constants.PROPERTY_STATUS_TRUE,
		};
		updateStore(properties);
		publishFirmwareDeviceSetting(properties, resourceList[2], publishPayload, false);
	};

	const onUpdateDeviceFirmware = () => {
		const properties = {
			"manual-dev-update": constants.PROPERTY_STATUS_TRUE,
		};
		updateStore(properties);
		publishFirmwareDeviceSetting(properties, resourceList[3], publishPayload, false);
	};

	const reclaimApiCall = async () => {
        const res = await axios.put(`/device/orgs/${orgId}/devices/${deviceId}/reclaim`, {}, Utils.requestHeader());
        setDeviceReclaimModal(true);
		if (res?.data?.meta?.code === 200) {
            setReclaimError('');
        } else {
            setReclaimError(res?.data?.meta?.userMsg);
			// TODO: show error in SiteToast
			console.error("ERROR: ", res?.data?.meta?.userMsg);
		}
	};

	const onDeleteDevice = () => {
		if (getLicenses() && getLicenses().length > 0) {
			let licenses = getLicenses()?.find((licenses) => licenses.deviceId === deviceId);
			if (licenses?.licenseStatus?.toUpperCase() === "ACTIVE" && !licenses?.trial) {
				setLicenceAttachedModal(true);
			} else {
				setShowDeviceRemoveModal(true);
			}
		} else {
			setShowDeviceRemoveModal(true);
		}
	};

	const deleteDeviceCall = async () => {
        try {
            setUserMsg('');
			const res = await axios.delete(`/device/orgs/${orgId}/devices/${deviceId}/v2`, Utils.requestHeader());
			if (parseInt(res?.status) === 202 || parseInt(res?.data?.meta?.code) === 200) {
				setShowDeviceRemoveModal(false);
				navigate(`/devices/listing.html?orgId=${orgId}`);
			} else {
                setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
            }
		} catch (error) {
			// TODO : to print the error will update this later
			console.error(error);
		}
	};

	 //Api Call to get firmware logs download and upload url
	 const getUploadDownloadCalls = async ()=>{
		if(timerRef?.current) {
		  clearTimeout(timerRef?.current);
		}
		const res = await axios.get(
		  `/device/orgs/${orgId}/devices/${deviceId}/logs`,
		  Utils.requestHeader()
		);
		if (
		  parseInt(res?.status) === 202 ||
		  parseInt(res?.data?.meta?.code) === 200
		){
		  setLogsData(res?.data?.data)
		  setEncriptionKey('')
		  setDownloadStatus(false)
		  setEncryptionStatus(true)
		  setShowDownloadLogsModal(true);
		  setUserMsg('')
		}
	   } 
	  
	   const onChangeHandler = (e) => {
		if (e != undefined) {
		  setEncriptionKey(e?.target?.value);
		}
	  };
	  
	  const publishEventForUpload =()=>{
		setDownloadStatus(true)
		let status = { name: logsData?.name, 'upload-url': logsData?.upload?.url };
		if(encryptionStatus){
		  status['enc'] = encriptionKey
		}
		publishFirmwareDeviceSetting(
		  status,
		  resourceList[4],
		  publishPayload,
		  false
		);
		timerRef.current = setTimeout(() => {
		  setEncriptionKey('');
		  setDownloadStatus(false);
		  setEncryptionStatus(true);
		  setShowDownloadLogsModal(false);
		  setUserMsg(constants.DOWNLOAD_ERROR_MEESAGE);
		}, 300000);
	  }
	  
	  useEffect(() => {
		if (
		  allMqttData?.msg?.resource === resourceList[4] &&
		  deviceId === allMqttData?.from
		) {
		  if (allMqttData?.msg?.action === 'error') {
			setEncriptionKey('');
			setDownloadStatus(false);
			setEncryptionStatus(true);
			setShowDownloadLogsModal(false);
			setUserMsg(allMqttData?.msg?.properties?.desc);
			if(timerRef?.current) {
				clearTimeout(timerRef?.current);
			}
		  } 
		  else if (allMqttData?.msg?.action === 'is' ) {
			if(timerRef?.current) {
				clearTimeout(timerRef?.current);
			}
			if(logsData?.download?.url){
			  downloadFile(logsData?.download?.url)    
			}
		  }  
		}
	  }, [allMqttData]);
	  
	  const downloadFile = (url) => {
		fetch(url, {
		  withCredentials: true,
		})
		  // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
		  .then((resp) =>
			resp.status === 200 ? resp.blob() : Promise.reject('something went wrong')
		  )
		  .then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = logsData?.name;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			//TODO Delete Later
			console.log('fileReady');
			setEncriptionKey('');
			setDownloadStatus(false);
			setEncryptionStatus(true);
			setShowDownloadLogsModal(false);
			setUserMsg("")
	  
		  })
		  .catch(
			(e) => {
			  //TODO Delete Later
			  console.log("error",e)
			  setEncriptionKey('');
			  setDownloadStatus(false);
			  setEncryptionStatus(true);
			  setShowDownloadLogsModal(false);
			  setUserMsg(constants.DoWNLOAD_ERROR);
			}
		  );
	  };

	  const avlblDeviceVer = deviceInfos?.properties?.['avlbl-device-ver'];
	  const firmwareVersion = deviceInfos?.properties?.['firmware-version'];

	return (
		<div className="text-start device-network-settings-block">
	     {userMsg && userMsg.length > 0 && (
           <div className="error-container">
              <div className="info-icon-container">
                <InfoCircleIcon />
             </div>
          <div className="error-body">
            <div className="error-content">{userMsg}</div>
            <div className="cross-icon-container">
              <RxCross1 onClick={() => setUserMsg('')} />
         </div>
        </div>
        </div>
          )}
			<Formik
				innerRef={formRef}
				enableReinitialize
				initialValues={{
					deviceRecord: deviceInfos?.properties?.["recEventBased"] === constants.PROPERTY_STATUS_TRUE,
				}}
				onSubmit={(values, { setSubmitting }) => {
					// TODO : Handle form submit use this later
					setSubmitting(true);
					setSubmitting(false);
					saveDeviceNetworkSettingsCallback(false);
				}}>
				{({ values, handleSubmit, setFieldValue }) => (
					<Form className="form" onSubmit={handleSubmit}>
                        <div className="section-heading mb-2">{constants.CHANNELS_INFO_NETWORK_DEVICE_MANAGEMENT_TITLE}</div>
                        <div className="my-4">
			<Row className="settings-block">
             <Col>
               <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="100%"
                    height="56px"
                    fontSize="1.125rem"
                    lineHeight="24px"
                    backgroundColor="transparent"
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    borderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    borderWidth="1px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    onClick={() => getUploadDownloadCalls()}
                    disabled={
                      connectionState ===
                        constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                      deviceState ===
                        constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
                      deviceState ===
                        constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
                    }
                  >
                    {constants.DOWNLOAD_BTN_TEXT}
                  </PrimaryButton>
                </Col>
              </Row>
							<Row className="settings-block">
								<Col>
									<PrimaryButton className="btn btn-primary-outline" type="button" width="100%" height="56px" fontSize="1.125rem" lineHeight="24px" backgroundColor="transparent" hoverBackgroundColor="transparent" borderColor={getComputedStyle(document.documentElement).getPropertyValue("--grayscale_96")} hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} borderWidth="1px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_72")} hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} onClick={() => setDeviceRestartModal(true)} disabled={connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS}>
										{constants.DEVICE_PASSWORD_TAB_RESTART_DEIVCE_BUTTON_TITLE}
									</PrimaryButton>
								</Col>
							</Row>
							<Row className="settings-block">
								<Col>
									<PrimaryButton className="btn btn-primary-outline" type="button" width="100%" height="56px" fontSize="1.125rem" lineHeight="24px" backgroundColor="transparent" hoverBackgroundColor="transparent" borderColor={getComputedStyle(document.documentElement).getPropertyValue("--grayscale_96")} hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} borderWidth="1px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_72")} hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} disabled={connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS} onClick={() => setDeviceFactoryResetModal(true)}>
										{constants.DEVICE_PASSWORD_TAB_FACTORY_RESET_BUTTON_TITLE}
									</PrimaryButton>
								</Col>
							</Row>
							{deviceInfos?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
								<Row className="settings-block">
									<Col>
										<PrimaryButton className="btn btn-primary-outline" type="button" width="100%" height="56px" fontSize="1.125rem" lineHeight="24px" backgroundColor="transparent" hoverBackgroundColor="transparent" borderColor={getComputedStyle(document.documentElement).getPropertyValue("--grayscale_96")} hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} borderWidth="1px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_72")} hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")} disabled={!deviceFactoryReset} onClick={() => reclaimApiCall()}>
											{constants.DEVICE_PASSWORD_TAB_RECLAIM_DEVICE_BUTTON_TITLE}
										</PrimaryButton>
									</Col>
								</Row>) : 
								null
							}
							<Row className="settings-block">
								<Col>
									<PrimaryButton
										className="btn btn-primary-outline"
										type="button"
										width="100%"
										height="56px"
										fontSize="1.125rem"
										lineHeight="24px"
										backgroundColor="transparent"
										hoverBackgroundColor="transparent"
										borderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
										hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
										borderWidth="1.5px"
										color={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
										hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
										disabled={
											!getCustomerOrgPolicies()?.delete_device ||
											(deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
												removeButtonDisableFlag === true)
										}
										onClick={() => onDeleteDevice()}
									>
										{constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_BUTTON_TITLE}
									</PrimaryButton>
								</Col>
							</Row>
						</div>
						{deviceInfos?.deviceType?.toUpperCase() !== DeviceTypeEnum.DMSERVER ? (
							<>
							{isCloudCapabilities && 
							<>
								<div className="section-heading mb-2">
									<label>{t("CHANNELS_NETWORK_EDGE_FIRMWARE_UPDATE_TITLE")}</label>
								</div>
								<div className="device-info-section">
									{connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS && deviceInfos?.properties?.["avlbl-duclo-ver"] && deviceInfos?.properties?.["edge-app-version"] !== deviceInfos?.properties?.["avlbl-duclo-ver"] && (
										<Row className="settings-block">
											<div className="firmware-update-section">
												<div className="alert mt-3 mb-0 alert-danger info-firmware" role="alert">
													<div className="alert-message">
														<HiOutlineExclamationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue("--error_24")} className="alert-icon" />
													</div>
													<p>
														{constants.FIRMWARE_UPDATE_MESSAGE}{" "}
														<span
															onClick={() => onUpdateFirmware()}
															style={{
																cursor: "pointer",
																textDecoration: "underline",
																fontWeight: 600,
																textDecorationColor: getComputedStyle(document.documentElement).getPropertyValue("--error_24"),
															}}>
															{constants.CHANNELS_INFO_UPDATE_NOW_TITLE}
														</span>
													</p>
												</div>
											</div>
										</Row>
									)}
									<div className={`firmware-container fm-container ${curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS ? " editable-off-offline" : ""}`}>
										<div className="firmware-update">
											<Col>
												<ToggleInput
													label={t("AUTO_UPDATE_FIRMWARE_VERSION")}
													name="fw-updt-type"
													value={autoUpdateEnable}
													labelColor={true}
													changeHandler={(e) => {
														setAutoUpdateEnable(!!!JSON.parse(e?.target?.value));
														const status = {
															"fw-updt-type": !!!JSON.parse(e?.target?.value) ? constants.UPDATE_FIRMWARE_AUTO : constants.UPDATE_FIRMWARE_MANUAL,
														};
														updateStore(status);
														publishFirmwareDeviceSetting(status, resourceList[2], publishPayload);
													}}
												/>
											</Col>
										</div>
										{autoUpdateEnable && (
											<>

												<div className="week-header mt-3 mb-2">
													<div className="choose-day">{constants.CHOOSE_DAY}</div>
													<div className="week-day">{selectDay}</div>
												</div>

												<div className="select-day-container">
													{daysList?.map((days) => {
														return (
															<div onClick={() => selectedDay(days)} key={days.id} className={`day-blocks ${days.isSelected ? "selected" : ""}`}>
																<div className="label-value">{days.dayPrefix}</div>
															</div>
														);
													})}
												</div>
												<div className="week-header choose-time mt-3 mb-2">
													<div className="choose-day">{constants.CHOOSE_TIME}</div>
												</div>
												<div className="time-container">
													<div className="timer-start-end">{renderTimePicker(startTime, true)}</div>
													<div className="seprator">-</div>
													<div className="timer-start-end">{renderTimePicker(endTime, false)}</div>
												</div>
												<div className="hour-diffrence mt-2">{constants.HOURS_DIFFERENCE}</div>
											</>
										)}
									</div>
									
								</div>
								</>
						}
						{isDeviceCapabilities && 
							<>
								<div className="section-heading mb-2 mt-4">
									<label>{constants.CHANNELS_NETWORK_DEVICE_FIRMWARE_UPDATE_TITLE}</label>
								</div>
								<div className="device-info-section mb-3">
									{connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS && avlblDeviceVer && firmwareVersion?.toLowerCase() !== avlblDeviceVer?.toLowerCase() && (
										<Row className="settings-block">
											<div className="firmware-update-section">
												<div
													className="alert mt-3 mb-0 alert-danger info-firmware"
													role="alert"
												>
													<div className="alert-message">
														<HiOutlineExclamationCircle
															size={15}
															stroke={getComputedStyle(
															document.documentElement
															).getPropertyValue('--error_24')}
															className="alert-icon"
														/>
													</div>
													<p>
														{constants.CHANNELS_INFO_UPDATE_DEVICE_FW_MESSAGE}{' '}
														<span
															onClick={() => onUpdateDeviceFirmware()}
															style={{
															cursor: 'pointer',
															fontWeight: 600,
															textDecoration: 'underline',
															textDecorationColor: getComputedStyle(
																document.documentElement
															).getPropertyValue('--error_24'),
															}}
														>
															{constants.CHANNELS_INFO_UPDATE_NOW_TITLE}
														</span>
													</p>
												</div>
											</div>
										</Row>
									)}
									<div className={`firmware-container fm-container device-firmware-container 
										${curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS ||
											deviceInfo?.deviceType?.toUpperCase()=== DeviceTypeEnum.DMSERVER ?
											" editable-off-offline" : ""}`}>
										<div className="firmware-update">
											<Col>
												<ToggleInput
													label={t("CHANNELS_INFO_AUTO_UPDATE_DEVICE_FW_TITLE")}
													name="fw-updt-dev-type"
													value={autoUpdateDeviceEnable}
													labelColor={true}
													changeHandler={(e) => {
														setAutoUpdateDeviceEnable(!!!JSON.parse(e?.target?.value));
														const status = {
															"fw-updt-dev-type": !!!JSON.parse(e?.target?.value) ? constants.UPDATE_FIRMWARE_AUTO : constants.UPDATE_FIRMWARE_MANUAL,
														};
														updateStore(status);
														publishFirmwareDeviceSetting(status, resourceList[3], publishPayload);
													}}
												/>
											</Col>
										</div>
										{autoUpdateDeviceEnable && (
												<>
												<div className="week-header mt-3 mb-2">
													<div className="choose-day">{constants.CHOOSE_DAY}</div>
													<div className="week-day">{selectDeviceDay}</div>
												</div>
												<div className="select-day-container">
													{deviceDaysList?.map((days) => {
														return (
															<div onClick={() => selectedDeviceDay(days)} key={days.id} className={`day-blocks ${days.isSelected ? "selected" : ""}`}>
																<div className="label-value">{days.dayPrefix}</div>
															</div>
														);
													})}
												</div>
												<div className="week-header choose-time mt-3 mb-2">
													<div className="choose-day">{constants.CHOOSE_TIME}</div>
												</div>
												<div className="time-container">
													<div className="timer-start-end">{renderDeviceTimePicker(deviceStartTime, true)}</div>
													<div className="seprator">-</div>
													<div className="timer-start-end">{renderDeviceTimePicker(deviceEndTime, false)}</div>
												</div>
												<div className="hour-diffrence mt-2">{constants.HOURS_DIFFERENCE}</div>
											</>
										)}
									</div>
								</div>
							</>
						}
						</>
						) : null
						}
					</Form>
				)}
			</Formik>
			{/* Restart device - modal  */}
			<SiteModal
				modalTitle={constants.RESTART_DEVICE_MODAL_TITLE}
				showModal={deviceRestartModal}
				hideModal={() => {
					setDeviceRestartModal(false);
				}}
				classes="device-settings-restart-required">
				<TextBlock className="mb-3" fontWeight="400" fontSize="1rem" lineHeight="24px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")}>
					{constants.RESTART_DEVICE_MODAL_CONTENT}
				</TextBlock>
				<PrimaryButton
					className="btn btn-primary-outline mb-4"
					type="button"
					width="100%"
					borderWidth="1.5px"
					hoverBorderWidth="1.5px"
					hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")}
					color={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")}
					hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")}
					hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")}
					backgroundColor="transparent"
					height="56px"
					fontSize="1.125rem"
					lineHeight="24px"
					onClick={() => {
						setDeviceRestartModal(false);
					}}>
					{constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE}
				</PrimaryButton>
				<PrimaryButton
					className="btn"
					type="button"
					width="100%"
					height="56px"
					fontSize="1.125rem"
					lineHeight="24px"
					backgroundColor="transparent"
					hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					borderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					borderWidth="1.5px"
					color={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")}
					onClick={() => {
						publishFirmwareDeviceSetting(false, resourceList[0], publishPayload, true);
						setDeviceRestartModal(false);
					}}>
					{constants.DEVICE_PASSWORD_TAB_RESTART_DEIVCE_BUTTON_TITLE}
				</PrimaryButton>
			</SiteModal>

			{/* device factory reset - modal  */}
			<SiteModal
				modalTitle={constants.FACTORY_RESET_MODAL_TITLE}
				showModal={deviceFactoryResetModal}
				hideModal={() => {
					setDeviceFactoryResetModal(false);
				}}
				classes="device-settings-restart-required">
				<TextBlock className="mb-4" fontWeight="400" fontSize="1rem" lineHeight="24px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")}>
					{t("FACTORY_RESET_MODAL_CONTENT")}
					<br />
					<br />
					{constants.FACTORY_RESET_MODAL_SUB_CONTENT}
				</TextBlock>

				<PrimaryButton
					className="btn btn-primary-outline mb-4 btn-primary"
					type="button"
					width="100%"
					borderWidth="1.5px"
					hoverBorderWidth="1.5px"
					height="56px"
					fontSize="1.125rem"
					lineHeight="24px"
					onClick={() => {
						setDeviceFactoryResetModal(false);
					}}>
					{constants.CANCEL_BUTTON_TITLE}
				</PrimaryButton>
				<PrimaryButton
					className="btn btn-primary-outline text-capitalize mt-1"
					type="button"
					width="100%"
					borderWidth="1.5px"
					hoverBorderWidth="1.5px"
					height="56px"
					fontSize="1.125rem"
					lineHeight="24px"
					borderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					color={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")}
					hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
					backgroundColor="transparent"
					onClick={() => {
						publishFirmwareDeviceSetting(false, resourceList[1], publishPayload, true);
						setDeviceFactoryResetModal(false);
					}}>
					{constants.RESET_FACTORY_DEFAULT_BUTTON_TITLE}
				</PrimaryButton>
			</SiteModal>

			{/* device reclaim - modal  */}
			<SiteModal
				modalTitle={constants.DEVICE_PASSWORD_TAB_RECLAIM_DEVICE_BUTTON_TITLE}
				showModal={deviceReclaimModal}
				hideModal={() => {
					setDeviceReclaimModal(false);
					setReclaimError("");
				}}
				classes="device-settings-reclaim-modal">
				{reclaimError ? (
					<div className="error-container">
						<div className="info-icon-container">
							<InfoCircleIcon />
						</div>
						<div className="error-body">
							<div className="error-content">{reclaimError}</div>
						</div>
					</div>
				) : (
					<>
						<TextBlock className="mb-1" fontWeight="700" fontSize="1.25rem" lineHeight="28px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_08")}>
							{constants.DEVICE_RECLAIM_IN_PROGRESS_TITLE}
						</TextBlock>

						<TextBlock className="mb-4" fontWeight="400" fontSize="1rem" lineHeight="24px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")}>
							{t("DEVICE_PASSWORD_TAB_RECLIAM_MODAL_CONTENT")}
						</TextBlock>
					</>
				)}
				<PrimaryButton
					className="btn btn-primary"
					type="button"
					width="100%"
					borderWidth="1.5px"
					hoverBorderWidth="1.5px"
					height="56px"
					fontSize="1.125rem"
					lineHeight="24px"
					onClick={() => {
						setDeviceReclaimModal(false);
					}}>
					{constants.BACK_TO_DEVICE_MANAGEMENT_TITLE}
				</PrimaryButton>
			</SiteModal>

			<SiteModal
				modalTitle={constants.UNABLE_TO_REMOVE_DEVICE_TITLE}
				showModal={licenceAttachedModal}
				hideModal={() => {
					setLicenceAttachedModal(false);
				}}
				classes="device-settings-reclaim-modal">
				<TextBlock className="mb-4" fontWeight="400" fontSize="1rem" lineHeight="24px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")}>
					{constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_CONTENT}
				</TextBlock>

				<PrimaryButton
					className="btn btn-primary"
					type="button"
					width="100%"
					borderWidth="1.5px"
					hoverBorderWidth="1.5px"
					height="56px"
					fontSize="1.125rem"
					lineHeight="24px"
					onClick={() => {
						setLicenceAttachedModal(false);
					}}>
					{constants.DEVICES_TAB_ANALYTICS_BACK}
				</PrimaryButton>
			</SiteModal>

			{/* Want to remove device - modal  */}
			<SiteModal
				modalTitle={licenseattachedStatus ? constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_TITLE : constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE}
				showModal={showDeviceRemoveModal}
				hideModal={() => {
					setShowDeviceRemoveModal(false);
					setUserMsg("");
				}}
				classes="device-settings-restart-required">
				{userMsg && userMsg.length > 0 && (
					<div className="error-container">
						<div className="info-icon-container">
							<InfoCircleIcon />
						</div>
						<div className="error-body">
							<div className="error-content">{userMsg}</div>
							<div className="cross-icon-container">
								<RxCross1 onClick={() => setUserMsg("")} />
							</div>
						</div>
					</div>
				)}
				<TextBlock className="mb-3" fontWeight="400" fontSize="1rem" lineHeight="24px" color={getComputedStyle(document.documentElement).getPropertyValue("--greyscale_56")}>
					{licenseattachedStatus ? constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_CONTENT : constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT}
				</TextBlock>

				{licenseattachedStatus ? (
					<PrimaryButton
						className="btn btn-primary-outline text-capitalize mt-1 btn-primary"
						type="button"
						width="100%"
						borderWidth="1.5px"
						hoverBorderWidth="1.5px"
						height="56px"
						fontSize="1.125rem"
						lineHeight="24px"
						onClick={() => {
							setShowDeviceRemoveModal(false);
						}}>
						{constants.BACK_BUTTON_TITLE}
					</PrimaryButton>
				) : (
					<>
						<div className="text-center">
							<HiExclamationCircle className="mb-4 icon-warning-color" size={96} />
						</div>
						<PrimaryButton
							className="btn btn-primary-outline mb-4"
							type="button"
							width="100%"
							borderWidth="1.5px"
							hoverBorderWidth="1.5px"
							hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")}
							color={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")}
							hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")}
							hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--primary_40")}
							backgroundColor="transparent"
							height="56px"
							fontSize="1.125rem"
							lineHeight="24px"
							onClick={() => {
                                setShowDeviceRemoveModal(false);
                                setUserMsg('');
							}}>
							{constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE}
						</PrimaryButton>
						<PrimaryButton
							className="btn"
							type="button"
							width="100%"
							height="56px"
							fontSize="1.125rem"
							lineHeight="24px"
							backgroundColor="transparent"
							hoverBackgroundColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							borderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							hoverBorderColor={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							borderWidth="1.5px"
							color={getComputedStyle(document.documentElement).getPropertyValue("--error_64")}
							hoverColor={getComputedStyle(document.documentElement).getPropertyValue("--brand_white")}
							onClick={() => {
								deleteDeviceCall();
							}}>
							{constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE}
						</PrimaryButton>
					</>
				)}
			</SiteModal>
			<SiteModal
        modalTitle={constants.DOWNLOAD_MODAL_TITLE_TEXT}
        showModal={showDownloadLogsModal}
        hideModal={() => {
          if (!downloadStatus) {
            setEncriptionKey('');
            setDownloadStatus(false);
            setEncryptionStatus(true);
            setLogsData(false);
            setShowDownloadLogsModal(false);
          }
        }}
        classes="device-settings-download-modal"
      >
        <div
          className={`inner-content-download-logs ${
            downloadStatus ? 'disable-action' : ''
          }`}
        >
          {downloadStatus && (
            <div
              className="site-spinner-download"
              style={{
                backgroundColor: getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_primary'),
              }}
            ></div>
          )}
          <div className="description">
            {constants.DOWNLOAD_MODAL_DESCRIPTION_TEXT}
          </div>
          <Row className="settings-block mt-4">
            <Col>
              <ToggleInput
                label={constants.DOWNLOAD_ENCRYPTION}
                name="encryptionStatus"
                changeHandler={(e) => {
                  setEncriptionKey('');
                  setEncryptionStatus(!!!JSON.parse(e?.target?.value));
                }}
                value={encryptionStatus}
              />
            </Col>
          </Row>
          {encryptionStatus && (
                <input
                  value={encriptionKey}
                  onChange={(e) => onChangeHandler(e)}
                  style={{height: "50px", width: '100%' }}
                  className={`form-control require shadow-none ${
                    encriptionKey ? 'has-value' : ''
                  }`}
                  placeholder={constants.DOWNLOAD_ENCRYPTION_PLACEHOLDER}
                  autoComplete="off"
                  beforeinputicon=""
                  afterinputicon=""
                />
          )}

          <div className="footer-layout">
            <div className="download-cancel-btn">
              <PrimaryButton
                className="btn"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                backgroundColor="transparent"
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderWidth="1.5px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                disabled={downloadStatus}
                onClick={() => {
                  //deleteDeviceCall();
                  setEncriptionKey('');
                  setDownloadStatus(false);
                  setEncryptionStatus(true);
                  setLogsData(false);
                  setShowDownloadLogsModal(false);
                }}
              >
                {constants.DOWNLOAD_CANCEL}
              </PrimaryButton>
            </div>
            <div className="download-btn">
              <PrimaryButton
                className="btn btn-primary"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={() => {
                  publishEventForUpload();
                }}
                disabled={
                  encryptionStatus
                    ? encriptionKey === '' || downloadStatus
                    : encryptionStatus || downloadStatus
                }
              >
                {constants.DOWNLOAD_START}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </SiteModal>
		</div>
	);
};

export default HubNetworkBlock;
