import axios from 'axios';
import Store from './Store';
import { getItem, setItem } from './indexDB/db';
import { Utils } from '../helpers';
import {
  setLoggedInUserRole,
  setPartnerOrgData,
  setSelfAccounData,
} from './reducers/OrganizationsReducer';

export const getOrganizations = async (route) => {
  if (!route) return;
  const res = await axios.get(route, Utils.requestHeader());
  const response = res?.data;
  let result;

  // Set organizations if request is successful
  if (response?.meta?.code === 200) {
    let organizationsListData = response?.data?.organizations;
    let loggedInUserData = response?.data?.account;
    if (loggedInUserData) {
      Store.dispatch(setSelfAccounData(loggedInUserData));
      Store.dispatch(setLoggedInUserRole(loggedInUserData?.role));
    }
    if (organizationsListData) {
      let primaryOrg = organizationsListData?.filter((e) => {
        return e.orgType === 'INSTALLER_ORG';
      });
      if (primaryOrg.length === 1) {
        let primaryOrgData = primaryOrg[0];
        Store.dispatch(setPartnerOrgData(primaryOrgData));
      }

      let custOrgs = organizationsListData?.filter((e) => {
        return e.orgType !== 'INSTALLER_ORG';
      });
      if (custOrgs.length >= 1) {
        await setCustomerOrgData(custOrgs);
      }
    }

    result = {
      status: 'success',
      msg: 'organizations listing data retrieved`',
    };
  } else {
    if (res?.code) {
      result = { status: 'failure', msg: `${res.code}: ${res.message}` };
    } else if (response?.data) {
      result = { status: 'failure', msg: response?.data?.userMsg };
    } else {
      result = { status: 'failure', msg: 'Unknown internal API error' };
    }
  }
  return result;
};

export const getCustomerOrgData = async () => {
  return await getItem('customerOrgData');
};

export const setCustomerOrgData = async (data) => {
  return await setItem('customerOrgData', data);
};

export const resetCustomerOrgData = async () => {
  return await setItem('customerOrgData', []);
};
