import { useEffect, useMemo, useState, useCallback } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  NoDataBlock,
  PrimaryButton,
  SiteModal,
  SiteToast,
  SiteSpinner
} from '../../components/common/';
import { constants, Utils } from '../../helpers/';
import OrgListTable from '../organizations/OrgListTable';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { NewOrganization } from './';
import { icontick, cross } from '../../assets/images';
import { BsExclamationLg } from 'react-icons/bs';
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
} from 'react-icons/hi';
import { RxPlus } from 'react-icons/rx';
import './ManageOrganizations.scss';
import axios from 'axios';
import {
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import { useLoggedInUserData } from "../../store/LoggedInAccountStore";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import {
  getCustomerOrgData,
  getOrganizations,
} from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

function PartnerLandingPage() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const token = keycloak.token;
  const locationData = localStorage.getItem('inviteData');
  const location = locationData && JSON.parse(locationData)
  const invitationData = location;
  const inviteCode = invitationData?.meta?.code;
  const inviteStatus = invitationData?.data?.accepted;
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const inviteTitle =
    inviteCode == 200
      ? inviteStatus
        ? constants.PARTNER_INVITE_ACCEPTED_TITLE
        : constants.PARTNER_INVITE_REJECTED_TITLE
      : inviteCode == 1032
      ? constants.PARTNER_INVITE_INVALID_TITLE
      : inviteCode == 1030
      ? constants.PARTNER_INVITE_PROCESSED_TITLE
      : inviteCode == 1031 || inviteCode == 1033
      ? constants.PARTNER_INVITE_EXPIRED_TITLE
      : invitationData?.meta?.desc;
  const inviteSubTitle =
    inviteCode == 200
      ? inviteStatus
        ? `${invitationData?.data?.orgName} ${constants.PARTNER_INVITE_ACCEPTED_SUB_TITLE}`
        : `${invitationData?.data?.orgName} ${constants.PARTNER_INVITE_REJECTED_SUB_TITLE}`
      : inviteCode == 1030
      ? ''
      : inviteCode == 1032
      ? invitationData?.meta?.userMsg?.replace('Invalid Account.', '')
      : invitationData?.meta?.userMsg;
  const [partnerInvitiationStatus, setPartnerInvitiationStatus] = useState(
    invitationData && Object.keys(invitationData).length > 0 ? true : false
  );

  //=== Store get/set actions
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  // const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );

  const [showToast, setShowToast] = useState(false);

  const [userMsg, setUserMsg] = useState('');
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [fetchOrgsStatus, setFetchOrgsStatus] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [licensesListByOrgIds, setLicensesListByOrgIds] = useState();
  // const [orgListWithLicences, setOrgListWithLicences] = useState(getCustomerOrgData());
  const [orgListWithLicences, setOrgListWithLicences] = useState();
  const [customerOrgDataList, setCustomerOrgDataList] = useState();
  const [isChanged, setIsChanged] = useState(false);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgListWithLicences(orgs);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  //=== Get organizations data
  const fetchOrganizations = async (token) => {
    if (token) {
      setShowSpinner(true);
      setFetchOrgsStatus(false);
      await getOrganizations(`/partner/account/self`);
      setShowSpinner(false);
      //=== Subscribe the topics for all added customer organizations
      subscribeWithOrgIds(orgListWithLicences);
    }else{
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (orgListWithLicences?.length > 0) {
      updateLicenseInfoForAllOrgs(orgListWithLicences);
    } else if (orgListWithLicences?.length === 0) {
      setCustomerOrgDataList(orgListWithLicences);
    }
  }, [orgListWithLicences]);

  useEffect(() => {
    if(licensesListByOrgIds?.length > 0) {
      const list = orgListWithLicences?.map(org => {
        return {
          ...org,
          expiredLicenseCount: licensesListByOrgIds.find(x => x?.orgId === org?.orgId)?.expiredLicenseCount || 0,
          expiringSoonLicenseCount: licensesListByOrgIds.find(x => x?.orgId === org?.orgId)?.expiringSoonLicenseCount || 0,
        }
      })
      setCustomerOrgDataList(list);
    }
    setIsChanged(!isChanged)
  }, [licensesListByOrgIds])

  useEffect(() => {
    if (customerOrgDataList) {
      setShowSpinner(false);
    }
  }, [customerOrgDataList]);

  const updateLicenseInfoForAllOrgs = (orgList) => {
    const licenseList = orgList?.map(async (org) => {
      if(org.role){
        const url = `/user/orgs/${org?.orgId}/licenses`;
        const license = await axios.get(url, Utils.requestHeader())
          .then((res) => {
            if (res && res.data) {
              const respData = res.data;
              const tempLic = {
                orgId: org.orgId,
                expiredLicenseCount: getExpiredLicenseCount(respData.data),
                expiringSoonLicenseCount: getExpiringSoonLicenseCount(respData.data)
              }
              return tempLic;
            }
          });
        return license;
      }
    });
    Promise.all(licenseList).then((values) => {
      setLicensesListByOrgIds(values);
    });
  }

  const getExpiredLicenseCount = (licenses) => {
    let count = 0;
    licenses.forEach((license) => {
      if (
        (license.licenseStatus === "EXPIRED" ||
        (license?.expiryDate &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) <= 0
        ))
      ) {
        count++;
      }
    });
    return count;
  }

  const getExpiringSoonLicenseCount = (licenses) => {
    let count = 0;
    licenses.forEach((license) => {
      if (
        license.licenseStatus !== "UNASSIGNED" &&
        license.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(license.expiryDate, moment().valueOf()) <=
          license.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(license.expiryDate, moment().valueOf()) >=
          0
      ) {
        count++;
      }
    });
    return count;
  };

  const columnsData = useMemo(
    () => [
      {
        Header: constants.NAME_TABLE_HEADER,
        accessor: 'orgName',
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['orgName'].toLowerCase() >
            sortAfter.values['orgName'].toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['orgName'].toLowerCase() <
            sortAfter.values['orgName'].toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.CAMERAS_OFFLINE_TABLE_HEADER,
        accessor: 'devices',
        Cell: (props) => {
            if (props?.value !== null && props?.value !== undefined) {
                let devices = props.value;
                if (devices.length > 0) {
                  const deviceList = Utils.getTotalChildDevices(devices);
                  const totalDevice = deviceList?.filter(
                      (device) =>
                        device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
                    );
                    const offlineCamerasCount = totalDevice?.filter((item) => item.connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS);
                    let onlineCameras = devices?.filter(
                        (devicObj) => devicObj.connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
                    );
                    return (
                        <>
                            {devices.length > 0 ? (
                                <>
                                    {devices?.length === onlineCameras?.length &&
                                        offlineCamerasCount?.length === 0 ? (
                                        <>
                                            {offlineCamerasCount?.length}
                                            <HiOutlineCheckCircle
                                                stroke={getComputedStyle(
                                                    document.documentElement
                                                ).getPropertyValue('--success_48')}
                                                className="device-offline-count-icon ms-1"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {offlineCamerasCount?.length} / {totalDevice?.length}
                                            <HiOutlineExclamationCircle
                                                stroke={getComputedStyle(
                                                    document.documentElement
                                                ).getPropertyValue('--error_64')}
                                                className="device-offline-count-icon ms-1"
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                0
                            )}
                        </>
                    );
                }
            }
            return 0;
        },
        sortType: 'basic',
      },
      {
        Header: constants.LICENSES_EXPIRED_TABLE_HEADER,
        accessor: 'expiredLicenseCount',
        Cell: (props) => {
          return props.value || 0;
        },
        sortType: 'basic',
      },
      {
        Header: constants.LICENSES_EXPIRING_TABLE_HEADER,
        accessor: 'expiringSoonLicenseCount',
        Cell: (props) => {
          return props.value || 0;
        },
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: 'orgId',
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: (props) => {
          return (
            ''
          );
        },
      },
    ],
    []
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'orgName',
        desc: true,
      },
      {
        id: 'devices',
        desc: true,
      },
    ],
    []
  );

  const hideModaInvite = ()=>{
    setPartnerInvitiationStatus(false)
    localStorage.removeItem('inviteData')
  }

  return (
    <div className="App partner-landing-page">
      {!showSpinner ?
        <div>
          <div className="page-header">
        <Container className='mw-100'>
          <Row>
            <div className="customer-list-toast-wrapper">
              <SiteToast
                customCss="customer-list-toast"
                position="top-end"
                show={showToast}
                title="Error"
                body={userMsg}
                delay={5000}
              />
            </div>
          </Row>
          <Row className="g-4">
            {loggedInUserData?.accountStatus !== constants.INACTIVE_USER_STATUS_LABEL.toUpperCase() &&
                <>
                    <Col md={6} lg={6} xl={6} xs={12} className="page-title text-start">
                        {constants.MANAGE_ORG_PAGE_TITLE}
                    </Col>
                    <Col
                        md={6}
                        lg={6}
                        xl={6}
                        xs={12}
                        className="text-end page-header-buttons"
                    >
                        {getLoggedInUserPolicies()?.create_cust_org && (
                            <PrimaryButton
                                className="btn btn-primary"
                                type="button"
                                width="auto"
                                onClick={() => {
                                    setShowAddOrgModal(true);
                                }}
                            >
                                <RxPlus
                                    className="plus-icon-btn"
                                    strokeWidth={'1.5px'}
                                    size={13.33}
                                />
                                {constants.MANAGE_ORG_ADD_ORG_BUTTON_TITLE}
                            </PrimaryButton>
                        )}
                    </Col>
                </>
            }
          </Row>
        </Container>
      </div>

      <div className="list-block">
        <Container className="h-100 mw-100">
          <>
            {getLoggedInUserPolicies()?.view_cust_org === true &&
                  (Array.isArray(customerOrgDataList) &&
                  customerOrgDataList?.length > 0 ? (
                    <OrgListTable
                      columns={columnsData}
                      data={customerOrgDataList}
                      defaultSortByData={sortingData}
                    />
                  ) : Array.isArray(customerOrgDataList) &&
                    !customerOrgDataList?.length ? (
                    <NoDataBlock
                      contentBlock={
                        constants.NO_MANAGE_ORG_CUSTOMER_FOUND_TITLE
                      }
                    />
                  ) : null)}

            {userMsg && (
              <div className="mt-5 mb-5">
                <strong>{userMsg}</strong>
              </div>
            )}
          </>
        </Container>
      </div>
        </div> :
          <div className='position-absolute top-50 l-48'>
            <SiteSpinner height="50px" width="50px" />
            <div className='mt-2 text-dark'>{constants.LOADING}</div>
          </div>
      }
      <SiteModal
        modalTitle={constants.NEW_CUSTOMER_ORG_PAGE_TITLE}
        showModal={showAddOrgModal}
        hideModal={() => setShowAddOrgModal(false)}
      >
        <NewOrganization
          reloadData={() => {
            fetchOrganizations(token);
          }}
          hideModal={() => setShowAddOrgModal(false)}
        />
      </SiteModal>
      <SiteModal
        modalTitle={inviteTitle}
        showModal={partnerInvitiationStatus}
        hideModal={() => hideModaInvite()}
        classes={'send-invitation-partner remove-org-modal'}
      >
        <div className={`sub-title-modal ${inviteCode == 1035 && 'error-m'}`}>
          {inviteSubTitle}
        </div>
        {
          <div>
            <div className="org-remove-invite-status">
              <div className="device-claimed-status">
                <div
                  className={`${
                    inviteCode == 200 && inviteStatus
                      ? 'claimed-view '
                      : (inviteCode == 200 && !inviteStatus) ||
                        inviteCode == 1032
                      ? 'claimed-view reject'
                      : (inviteCode == 1030 ||
                          inviteCode == 1031 ||
                          inviteCode == 1033 || inviteCode == 1035) &&
                        'claimed-view warning'
                  }`}
                >
                  {inviteCode == 200 && (
                    <img
                      className="image-tick"
                      src={inviteStatus ? icontick : cross}
                    ></img>
                  )}
                  {(inviteCode == 1030 ||
                    inviteCode == 1031 ||
                    inviteCode == 1033 || inviteCode == 1035) && (
                    <BsExclamationLg size={40} color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--warning_24')} />
                  )}
                  {inviteCode == 1032 && (
                    <img className="image-tick" src={cross}></img>
                  )}
                </div>
              </div>
            </div>
            <PrimaryButton
              className="btn mt-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_72')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                hideModaInvite()
              }}
            >
              {constants.PARTNER_INVITE_BTN_LABEL}
            </PrimaryButton>
          </div>
        }
      </SiteModal>
    </div>
  );
}

export default PartnerLandingPage;
