import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  partnerOrgData: [],
  loggedInUserRole: '',
  selfAccount: {},
};

export const organizations = createSlice({
  name: 'Organizations',
  initialState,
  reducers: {
    setPartnerOrgData: (state, action) => {
      state.partnerOrgData = action.payload;
    },
    setLoggedInUserRole: (state, action) => {
      state.loggedInUserRole = action.payload;
    },
    resetLoggedInUserRole: (state) => {
      state.loggedInUserRole = '';
    },
    setSelfAccounData: (state, action) => {
      state.selfAccount = action.payload;
    },
    resetSelfAccountData: (state) => {
      state.selfAccount = {};
    },
  },
});

export const {
  setPartnerOrgData,
  setLoggedInUserRole,
  resetLoggedInUserRole,
  setSelfAccounData,
  resetSelfAccountData,
} = organizations.actions;
export const getPartnerOrgData = (state) => state?.organizations?.partnerOrgData;
export const getLoggedInUserRole = (state) =>
  state.organizations?.loggedInUserRole;
export const getSelfAccountData = (state) => state?.organizations?.selfAccount;
export default organizations.reducer;
