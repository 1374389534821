import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import {
  AvatarList,
  BreadcrumbList,
  Header,
  LicensesList,
  ListBlock,
  PrimaryButton,
  ProgressBar,
  ScrollbarWrapper,
  SiteModal,
  SiteSpinner,
} from '../../components/common/';
import PageWrapper from '../../pages/PageWrapper';
import {
  AppDefaults,
  constants,
  Utils,
  ServiceTypeEnum,
  roles,
} from '../../helpers/';
import { EditOrganization } from '../../pages/organizations/';
import { useSelector, useDispatch } from 'react-redux';
import { icontick, noTechAssign } from '../../assets/images';

import {
  orgUserData,
  // setAllDevicesData,
  setLocationsData,
  // setSelectedOrganization,
  // getAllDevicesData,
  getLocationsData,
  // getSelectedOrganization,
} from '../../store/reducers/AccountReducer';
import { HiOutlinePencil } from 'react-icons/hi2';
import { VscCopy } from 'react-icons/vsc';
import useLicensesStore from '../../store/LicensesStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePoliciesStore } from '../../store/policiesStore';

import { RxCheckCircled } from 'react-icons/rx';
import { FiVideo } from 'react-icons/fi';
import LocationsMap from './LocationsMap';
import { HiUserCircle } from 'react-icons/hi2';
import { mqttUnsubscribe } from '../../utils/connection/mqttConnection';
import { getPlatformInfo } from '../../store/reducers/StreamingReducer';
import { getAccountId } from '../../store/reducers/AccountReducer';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import ReactSlider from 'react-slider';

import './OrganizationDetails.scss';
import CameraAvailability from '../analytics/CameraAvailability';
import DeviceUptime from './DeviceUptime';
import RemoveOrganization from './RemoveOrganization';

import { TbDeviceComputerCameraOff } from 'react-icons/tb';
import { PiWarningCircleLight, PiInfo } from 'react-icons/pi';
import { useLayoutEffect } from 'react';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { isObject } from 'lodash';
import { _ } from 'lodash';
import { useCallback } from 'react';
import { moveArrayPosition } from '../../helpers/commonUtils';
import { BsInfoCircle } from 'react-icons/bs';
import { setManufacturerSKUList } from '../../store/reducers/NVRDeviceReducer';
import {
  getCustomerOrgData,
  getOrganizations,
} from '../../store/OrganizationsStoreIDB';
import {
  getLoggedInUserRole,
  getPartnerOrgData,
  getSelfAccountData,
  setLoggedInUserRole,
} from '../../store/reducers/OrganizationsReducer';
import {
  getAllDevicesData,
  setAllDevicesData,
  getSelectedOrganization,
  setSelectedOrganization,
} from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import {
  fetchCustomerOrgDevices,
  getCustomerOrgDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';

function OrganizationDetails(props) {
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(
    constants.ORG_EMAIL_ERROR
  );
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  //153894 get user data from reducer
  const { allDevices, userdata, orgUsers } = useSelector(
    (state) => state.accounts
  );
  const locationsData = useSelector(getLocationsData);
  const [onlinestatus, setOnlinestatus] = useState(0);
  const [oflinestatus, setOflinestatus] = useState(0);
  const [userId, setUserid] = useState();
  const [nonInstallerUsers, setNonInstallerUsers] = useState([]);
  const [installerUsers, setInstallerUsers] = useState([]);
  const [unclaimed, setUnclaimed] = useState(0);
  const [expiredLicenses, setExpiredLicenses] = useState(0);
  const [expiringLicenses, setExpiringLicenses] = useState(0);
  const [availableLicenses, setAvailableLicenses] = useState(0);
  const [usedLicenses, setUsedLicenses] = useState(0);
  const [offlinenotPendingclaim, setOfflinenotPendingclaim] = useState(0);
  const [editUser, setEditUser] = useState({});
  const [custPartnerOrgData, setCustPartnerOrgData] = useState({});
  const [
    deviceWithUpdatedFirmwareVersion,
    setDeviceWithUpdatedFirmwareVersion,
  ] = useState(-1);
  // TODO : use later after demo2
  const [showRemovePartnerModal, setShowRemovePartnerModal] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);
  const [invitePartnerModal, setInvitePartnerModal] = useState(false);
  const [invitationSend, setInvitationSend] = useState(false);
  const [orgContact, setOrgContact] = useState([]);
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgId, editUser?.orgName)?.url,
      title: Utils.CheckLoggedInUserRole(orgId, editUser?.orgName)?.title,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: editUser?.orgName,
    },
  ];
  const updatedBreadList = Utils.CheckBreadcrumbForUserRole(breadList);
  const [locationCoordinates, setlocationCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [totalDevicesCount, setTotalDevicesCount] = useState(0);
  const [totalOnlineDevicesCount, setTotalOnlineDevicesCount] = useState(0);
  //=== Store get/set actions
  const { setLicenses, getLicenses } = useLicensesStore();
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const [custOrgData, setCustOrgData] = useState([]);

  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const getOrganizations = useOrganizations((state) => state.getOrganizations);
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  // const deviceListData = useSelector(getAllDevicesData);
  const [deviceListData, setDeviceListData] = useState([]);
  const platformDetails = useSelector(getPlatformInfo);
  const accountId = useSelector(getAccountId);
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const getLoggedInUserUUID = useLoggedInUserData(
    (state) => state.getLoggedInUserUUID
  );
  // const getSelfAccountData = useOrganizations(
  //   (state) => state.getSelfAccountData
  // );
  const selfAccountData = useSelector(getSelfAccountData);
  const [devices, setDevices] = useState([]);
  const [updatedDevice, setUpdatedDevice] = useState([]);
  // const { fetchCustomerOrgDevices, getCustomerOrgDevices } =
  //   useCustomerOrgDevices();
  const {
    getSelectedLocation,
    getCustomerOrgLocationsData,
    getCustomerOrgLocations,
  } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(accountId);
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact
  );
  const partnerOrgData = useSelector(getPartnerOrgData);
  const partnerOrgId = partnerOrgData?.orgId;

  const setPatnerOrgUser = useCustomerOrgUsers(
    (state) => state.setPatnerOrgUser
  );
  const getPartnerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getPartnerOrgUsersData
  );
  const [customerTechAccounts, setCustomerTechAccounts] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipOrgNameVisible, setTooltipOrgNameVisible] = useState(false);
  const [hoverTooltipOrgNameVisible, setHoverTooltipOrgNameVisible] = useState(false);
  const [hoverTooltipOrgIDVisible, setHoverTooltipOrgIDVisible] = useState(false);
  
  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs || []);
  }, []);

  const loadCustomerOrgDevices = useCallback(async () => {
    const devices = await getCustomerOrgDevices();
    setDeviceListData(devices || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  const debouncedLoadCustomerOrgDevices = useDebouncedCallback(
    loadCustomerOrgDevices,
    1000
  );

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === "customerOrgData") {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === "customerOrgDevices") {
        await debouncedLoadCustomerOrgDevices();
      }
      if (data.key === "selectedOrganization") {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadCustomerOrgDevices();
    debouncedLoadSelectedOrgData();
    
    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadCustomerOrgData,
    debouncedLoadCustomerOrgDevices,
    debouncedLoadSelectedOrgData,
  ]);

  useLayoutEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    fetchManufacturerData();
  }, []);

  const fetchManufacturerData = async () => {
    await axios
      .get('/device/manufacturerSkuBanks', Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData?.meta?.code === 200) {
          const responseData = resultData.data;
          dispatch(setManufacturerSKUList(responseData));
        } else {
          dispatch(setManufacturerSKUList([]));
        }
      });
  };
  useEffect(() => {
    if (partnerOrgId && editUser.role) {
      // get data for organization contact dropdown
      const roleParam = encodeURIComponent(roles.ROLE2PORTAL);
      getOrganizationContact(
        `/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParam}`
      )
        .then((res) => {
          let updatedContacts = res ?? [];
          if (editUser?.contactId) {
            const selectedObjIndex = res?.findIndex(
              (data) => data?.value === editUser?.contactId
            );
            updatedContacts = moveArrayPosition(res, selectedObjIndex, 0);
          }
          setOrgContact(updatedContacts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [JSON.stringify(editUser)]);

  useLayoutEffect(() => {
    if (editUser.orgId && !editUser.role) {
      getOrgAdmins();
    }
  }, [editUser.orgId]);

  const getOrgAdmins = () => {
    setOrgAdmins([]);
    setShowLoader(true);
    axios
      .get(
        `partner/orgs/${partnerOrgId}/custAccounts?custOrgId=${orgId}`,
        Utils.requestHeader()
      )
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setOrgAdmins(responseData);
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    if (deviceListData?.length) {
      const deviceList = Utils?.getTotalParentDevices(deviceListData);
      setDevices(getCurrentLocationDevices(deviceList));
    }
    const currentLoc = selectedLocation?.filteredLocationData?.find(
      (item) =>
        item?.locationId === selectedLocation?.locationId ||
        item?.isDefault === 'true'
    );
    if (currentLoc) {
      setlocationCoordinates({ lat: currentLoc?.lat, lng: currentLoc?.lng });
    }

  }, [selectedLocation, deviceListData?.length]);

  useEffect(() => {
    let count = 0;
    const deviceListChild = Utils.getTotalChildDevices(deviceListData);
    const deviceListParent = Utils.getTotalParentDevices(
      deviceListData
    )?.filter(
      (device) =>
        device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
    );
    const deviceList = deviceListChild?.filter(
      (device) =>
        device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
    );
    if (deviceListParent?.length > 0) {
      deviceListParent?.forEach((item) => {
        const deviceProperty = item?.properties;
        const avlbleDeviceVersion = deviceProperty?.['avlbl-device-ver'];
        const firmwareVersion = deviceProperty?.['firmware-version'];
        const avlbleDucloVersion = deviceProperty?.['avlbl-duclo-ver'];
        const edgeVersion = deviceProperty?.['edge-app-version'];
        let checkValue;
        checkValue =
          deviceProperty && avlbleDucloVersion
            ? avlbleDucloVersion !== edgeVersion
            : false;
        const checkStatus =
          avlbleDeviceVersion && firmwareVersion
            ? avlbleDeviceVersion?.toLowerCase() !==
              firmwareVersion?.toLowerCase()
            : false;
        if (!checkValue) {
          checkValue = checkStatus;
        }
        if (checkValue) {
          count = count + 1;
        }
      });
      setDeviceWithUpdatedFirmwareVersion(count);
    } else {
      setDeviceWithUpdatedFirmwareVersion(0);
    }

    if (deviceList?.length > 0) {
      setTotalDevicesCount(deviceList?.length);
      const onlineDevices = deviceList?.filter(
        (device) =>
          device.connectionStatus ===
            constants.DEVICES_ONLINE_CONNECTION_STATUS &&
          device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
      );
      setTotalOnlineDevicesCount(onlineDevices?.length);
    } else {
      setTotalOnlineDevicesCount(0);
      setTotalDevicesCount(0);
    }
  }, [deviceListData]);

  const getCurrentLocationDevices = (deviceList) => {
    if (selectedLocation) {
      const selectedLocationList = deviceList?.filter(
        (item) => item?.locationId === selectedLocation?.locationId
      );
      if (selectedLocation?.locationId === AppDefaults?.ALL_LOCATIONS_ID) {
        if (deviceList?.length > 0) {
          return deviceList;
        }
      } else if (selectedLocationList?.length > 0) {
        return selectedLocationList;
      } else {
        return [];
      }
    } else {
      if (deviceList?.length > 0) {
        return deviceList;
      } else {
        return [];
      }
    }
  };

  useLayoutEffect(() => {
    if (editUser.role) {
      fetchDevices();
    }
  }, [JSON.stringify(editUser)]);

  const deletePartner = () => {
    axios
      .delete(`partner/orgs/${orgId}/partnerOrgMapping`, Utils.requestHeader())
      .then((response) => {
        if (response?.data?.meta?.code === 200) {
          setShowRemovePartnerModal(false);
          getPartnerOrg();
          fetchUser();
        } else {
          setShowRemovePartnerModal(false);
        }
      });
  };

  const fetchDevices = useCallback(async () => {
    try {
      // Get all locations for customer's organization
      const custOrgLocations = getCustomerOrgLocationsData();

      if (custOrgLocations.length < 1) {
        await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      }
      // Fetch the list of devices associated with
      // the user
      let fetchResult = await fetchCustomerOrgDevices(
        `device/orgs/${orgId}/devices`
      );

      const locationIds = new Set();
      let deviceArea, currentArea, cameraDevice;
      if (fetchResult.status === 'success') {
        const cameraDevices = await getCustomerOrgDevices();
        getCapabiltiesFromDeviceList(cameraDevices);
        const devicePropertyErrorUnSubscribe = {
          topic: `d/notify/${accountId}/${getLoggedInUserUUID()}`,
        };
        mqttUnsubscribe(devicePropertyErrorUnSubscribe);
        const orgLocations = getCustomerOrgLocationsData();
        const locationAreas = [];

        // Retrieve the location name for each device
        for (let i = 0; i < cameraDevices.length; i++) {
          cameraDevice = cameraDevices[i];
          locationIds.add(cameraDevice?.locationId);

          currentArea = orgLocations.find(
            (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === cameraDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: cameraDevice?.deviceId,
              areas: [...currentArea?.areas],
            };

            locationAreas.push({
              locationId: cameraDevice?.locationId,
              areas: [deviceArea],
            });
          }

          cameraDevices[i].locationName = currentArea?.locationName;

          cameraDevices[i].areas = [
            ...locationAreas.find(
              (locationArea) =>
                locationArea.locationId === cameraDevices[i].locationId
            ).areas[0].areas,
          ];

          cameraDevices[i].style = null;
        }
        await new Promise((resolve) => {
          setUpdatedDevice(cameraDevices?.length > 0 ? [...cameraDevices] : []);
          setAllDevicesData(
            cameraDevices?.length > 0 ? [...cameraDevices] : []
          )
          resolve();
        });

        let filteredDevices;

        if (selectedLocation && isObject(selectedLocation)) {
          if (selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID) {
            filteredDevices = cameraDevices.filter(
              (device) =>
                device?.locationId === selectedLocation?.locationId &&
                device?.deviceStatus === 'CLAIMED'
            );
          } else {
            const list = cameraDevices?.filter(
              (device) => device?.deviceStatus === 'CLAIMED'
            );
            filteredDevices = cameraDevices?.length > 0 ? [...list] : [];
          }
        }
        await new Promise((resolve) => {
          const list = cameraDevices?.filter(
            (device) => device?.deviceStatus === 'CLAIMED'
          );
        });
      } else {
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    const init = async () => {
      if (Array.isArray(custOrgData)) {
        const userIndex = custOrgData?.find(
          (user) => user.orgId === orgId
        );
        await setSelectedOrganization(userIndex);
        setEditUser({
          ...userIndex,
        });
        setOnlinestatus(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.connectionStatus === 'online'
          )
        );
        setOflinestatus(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.connectionStatus === 'offline'
          )
        );
        setUnclaimed(
          userIndex?.devices?.filter(
            (devicObjj) => devicObjj.deviceStatus === 'PENDING_CLAIM'
          )
        );
        setOfflinenotPendingclaim(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.deviceStatus !== 'PENDING_CLAIM'
          )
        );
        setUserid(userIndex?.orgId === orgId);
      }
    }
    init();
  }, [custOrgData?.length]);

  useEffect(() => {
    const init = async () => {
      if (editUser.role) {
        // getDevices();
        getLocations();
        getPartnerOrg();
        fetchData();
      }

      if (Array.isArray(custOrgData)) {
        const userIndex = custOrgData?.find(
          (user) => user.orgId === orgId
        );
        await setSelectedOrganization(userIndex);
        setEditUser({
          ...userIndex,
        });
        setOnlinestatus(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.connectionStatus === 'online'
          )
        );
        setOflinestatus(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.connectionStatus === 'offline'
          )
        );
        setUnclaimed(
          userIndex?.devices?.filter(
            (devicObjj) => devicObjj.deviceStatus === 'PENDING_CLAIM'
          )
        );
        setOfflinenotPendingclaim(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.deviceStatus !== 'PENDING_CLAIM'
          )
        );
        setUserid(userIndex?.orgId === orgId);
      }

    if (getCustomerOrgUsersData()?.length > 0) {
      const userRoleCondition1 = getCustomerOrgUsersData()?.filter(
        (user) =>
          user.role === roles.ROLE2PORTAL ||
          user.role === roles.ROLE3PORTAL ||
          user.role === roles.ROLE7PORTAL
      );

        setNonInstallerUsers(userRoleCondition1);
      }
    }
    init();
  }, [JSON.stringify(editUser), custOrgData?.length]);

  const fetchData = async () => {
    try {
      // Retrieve available licenses
      let licensesLoadSuccess = await setLicenses(
        `/user/orgs/${orgId}/licenses`
      );

      if (licensesLoadSuccess?.status === 'success') {
        // Get all licenses that are available
        let custLicenses = getLicenses(false, true);

        // Get Available licenses
        setAvailableLicenses(
          custLicenses.filter(
            (license) =>
              license.licenseStatus === 'UNASSIGNED' ||
              license.licenseStatus === 'ACTIVE_UNASSIGNED'
          ).length
        );
        // Get all licenses that have expired
        setExpiredLicenses(
          custLicenses.filter(
            (license) =>
              (license.licenseStatus === 'EXPIRED' ||
                (license.expiryDate &&
                  Utils.getDateDifferenceInDays(
                    license.expiryDate,
                    moment().valueOf()
                  ))) <= 0
          ).length
        );
        // Get all licenses that are expiring soon
        setExpiringLicenses(
          custLicenses.filter(
            (license) =>
              license.licenseStatus !== 'UNASSIGNED' &&
              license.expiryDate &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) <= license.expiringSoonInDays &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) >= 0
          ).length
        );
        // Get all licenses  that are active
        setUsedLicenses(
          custLicenses.filter(
            (license) =>
              (license.licenseStatus === 'ACTIVE' ||
                license.licenseStatus === 'ASSIGNED_ACTIVATION_PENDING') &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) >= 0
          ).length
        );
      } else {
        // TODO: implement this later
        // setUserMsg(licensesLoadSuccess.msg);
        // setShowToast(true);
      }
    } catch (error) {
      // TODO: implement this later
      // setUserMsg(error);
      // setShowToast(true);
    } finally {
      // TODO: implement this later
      // setShowLoader(false);
    }
  };

  const onChangeEdit = (values) => {
    setEditUser({ ...editUser, ...values });
  };

  const getDevices = () => {
    axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            getCapabiltiesFromDeviceList(responseData);
            const devicePropertyErrorUnSubscribe = {
              topic: `d/notify/${accountId}/${getLoggedInUserUUID()}`,
            };
            mqttUnsubscribe(devicePropertyErrorUnSubscribe);
          }
        }
      });
  };

  const getCapabiltiesFromDeviceList = async (deviceData) => {
    let deviceList = [...deviceData];
    let newDeviceList = deviceList?.map(async (device, index) => {
      const deviceUnSubscription = {
        topic: Utils.replaceStringValues(
          subscribeTopic[0],
          '${deviceId}',
          `${device?.deviceId}`
        ),
      };
      mqttUnsubscribe(deviceUnSubscription);
      const deviceInfo = deviceListData?.find(
        (deviceStore) => deviceStore?.deviceId === device?.deviceId
      );
      // let isCapabilityCall =
      //   deviceListData?.length === 0 ||
      //   deviceInfo?.capDetails === undefined ||
      //   deviceInfo?.capability?.version !== device?.capability?.version;
      // if (device?.capability && isCapabilityCall) {
      //   let response = await fetch(device.capability.url);
      //   if (response.status === 200) {
      //     let responseJson = await response.json();
      //     return { ...device, capDetails: responseJson };
      //   } else {
      //     return { ...device };
      //   }
      // } else 
      if (
        device?.capability &&
        deviceInfo?.capability?.version === device?.capability?.version
      ) {
        return { ...device, capDetails: deviceInfo?.capDetails };
      } else {
        return { ...device };
      }
    });
    let deviceCapability = await Promise.all(newDeviceList);
    await setAllDevicesData(deviceCapability);
  };

  const getLocations = () => {
    axios
      .get(`partner/orgs/${orgId}/locations`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            const data = [...responseData];
            const defaultLocation = data?.filter(
              (location) => location.isDefault === 'true'
            );
            setlocationCoordinates({
              lat: defaultLocation[0]?.lat,
              lng: defaultLocation[0]?.lng,
            });
            const finalData = data.sort(function (x, y) {
              return x === defaultLocation[0]
                ? -1
                : y === defaultLocation[0]
                ? 1
                : 0;
            });
            dispatch(setLocationsData(finalData));
          }
        }
      });
  };

  const getPartnerOrg = () => {
    setInvitationSend(false);
    setInvitePartnerModal(false);
    setEmailInput('');
    setEmailError(false);
    axios
      .get(`partner/orgs/${orgId}/partnerOrg`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setCustPartnerOrgData(responseData);
          } else {
            setCustPartnerOrgData({});
          }
        }
      });
  };

  //=== Get organizations data
  const fetchOrganizations = async () => {
    await getOrganizations(`/partner/account/self`);
  };

  // Date format
  const newdate = moment(Utils.getDate(editUser?.createdDate / 1000)).format(
    'MMMM Do, YYYY'
  );

  const unclaimedpercentage = (
    (unclaimed?.length / editUser?.devices?.length) *
    100
  ).toFixed(2);
  const offlinePercentage = (
    (Utils.getOfflineCameras(allDevices) / editUser?.devices?.length) *
    100
  ).toFixed(2);
  const onlinestatusPercentage = (
    (onlinestatus?.length / editUser?.devices?.length) *
    100
  ).toFixed(2);
  const zeroDevices = editUser?.devices?.length / 100;
  const locationAddress = `${editUser?.addressLine1}, ${editUser?.city}, ${editUser?.city}, ${editUser?.country}`;
  let defaultLocation = editUser?.locations?.filter(
    (location) => location.isDefault.toString() === 'true'
  );
  //   let locationCoordinates = {
  //     lat: 0,
  //     lng: 0,
  //   };
  //   if (defaultLocation?.length > 0) {
  //     locationCoordinates.lat = defaultLocation[0]?.lat;
  //     locationCoordinates.lng = defaultLocation[0]?.lng;
  //   }

  const truncate = (string, length, delimiter) => {
    delimiter = delimiter || '...';
    return string.length > length
      ? string.substr(0, length) + delimiter
      : string;
  };

  const dashboardLocation = truncate(locationAddress, 65);

  const fetchUser = async () => {
    try {
      await axios
        .get(`partner/orgs/${orgId}/accounts`, Utils.requestHeader())
        .then((response) => {
          let resultData = response?.data?.data;
          setCustomerOrgUsers(resultData);
          dispatch(orgUserData(resultData));
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const fetchUserTech = async () => {
    try {
      await axios
        .get(`partner/orgs/${partnerOrgId}/accounts`, {
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (
            response?.data?.meta?.code === 200 ||
            response?.data?.meta?.code === '200'
          ) {
            setPatnerOrgUser(response?.data?.data);
          }
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const fetchCustomerTechAccounts = async () => {
    try {
      await axios
        .get(
          `/partner/orgs/${partnerOrgId}/customerTechAccounts?custOrgId=${orgId}`,
          Utils.requestHeader()
        )
        .then((response) => {
          let resultData = response?.data?.data;
          setCustomerTechAccounts(resultData);
          if (!editUser.role) {
            setCustomerOrgUsers(resultData);
            dispatch(orgUserData(resultData));
          }
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  useEffect(() => {
    if (orgId && editUser.role) {
      fetchUser();
    }
    if (orgId && partnerOrgId && loggedInUserData?.orgType !== 'CUSTOMER_ORG') {
      fetchCustomerTechAccounts();
    }
    if (partnerOrgId) {
      fetchUserTech();
    }
  }, [orgId, JSON.stringify(editUser)]);

  useEffect(() => {
    if (
      customerTechAccounts?.length &&
      Array.isArray(getPartnerOrgUsersData()) &&
      getPartnerOrgUsersData().length
    ) {
      setInstallerUsers([]);
      const custTechs = [];
      customerTechAccounts?.forEach((custData) => {
        const custDetail = getPartnerOrgUsersData()?.find(
          (detail) => detail.accountId === custData.accountId
        );
        custTechs.push(custDetail);
      });
      setInstallerUsers(custTechs);
    }
  }, [getPartnerOrgUsersData(), customerTechAccounts]);

  useEffect(() => {
    if (Array.isArray(getCustomerOrgUsersData())) {
      const userRoleCondition1 = getCustomerOrgUsersData()?.filter(
        (user) =>
          user.role === roles.ROLE2PORTAL ||
          user.role === roles.ROLE3PORTAL ||
          user.role === roles.ROLE7PORTAL
      );
      setNonInstallerUsers(userRoleCondition1);
      const instUsers = getCustomerOrgUsersData()?.filter(
        (user) => user.role === roles.ROLE1PORTAL
      );
      setInstallerUsers(instUsers);
    }
  }, [getCustomerOrgUsersData()]);

  const goToDeviceList = () => {
    if (getCustomerOrgPolicies()?.view_device) {
      navigate(`/devices/listing.html?orgId=${orgId}`);
    }
  };

  const goToLisenses = () => {
    navigate(
      `/licenses/listing.html?orgId=${orgId}&orgName=${editUser?.orgName}`
    );
  };

  const goToLocations = () => {
    if (getCustomerOrgPolicies()?.view_location) {
      navigate(`/locations/manage.html?orgId=${orgId}`);
    }
  };

  const goToUser = () => {
    if (getCustomerOrgPolicies()?.view_account) {
      navigate(`/users/manage.html?orgId=${editUser?.orgId}`);
    }
  };

  const partnerFlow = () => {
    if (Object.keys(custPartnerOrgData)?.length > 0) {
      setShowRemovePartnerModal(true);
    } else {
      setInvitePartnerModal(true);
    }
  };

  const onKeyDownChange = (e) => {
    if (e != undefined) {
    }
  };

  const onHandleChange = (e) => {
    setEmailError(false);
    if (e != undefined) {
      setEmailInput(e?.target?.value);
    }
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const sendInvite = async () => {
    const reqBody = {
      email: emailInput,
    };
    const resposne = await axios.post(
      `/partner/orgs/${editUser?.orgId}/invite`,
      reqBody,
      Utils.requestHeader()
    );
    if (resposne?.data?.meta?.code === 200) {
      setInvitationSend(true);
    } else {
      setEmailErrorMessage(resposne?.data?.meta?.userMsg);
      setTimeout(() => {
        setEmailError(true);
      }, 200);
      console.error('ERROR: ', resposne?.data);
    }
  };

  const hideInviteModal = () => {
    setInvitePartnerModal(false);
    setEmailInput('');
    setEmailError(false);
  };

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT == AppDefaults.PROJECT_MEGATRON) {
      document.documentElement.style.setProperty(
        '--backgroundColor',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--hGreyscale_88'
        )
      );
    } else {
      document.documentElement.style.setProperty(
        '--backgroundColor',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--primary_32'
        )
      );
    }
  }, []);

  const copyToOrgId = () => {
    navigator.clipboard.writeText(orgId);
    setTooltipVisible(true);
    setHoverTooltipOrgIDVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1500);
  };

  const copyToOrgName = () => {
    navigator.clipboard.writeText(orgDetails?.shortName);
    setTooltipOrgNameVisible(true);
    setHoverTooltipOrgNameVisible(true);
    setTimeout(() => {
      setTooltipOrgNameVisible(false);
    }, 1500);
  };

  return (
    <div className="App org-detail-page">
      <Header breadcrumbData={breadList} />
      <div className="organization-bg">
        <Container className="mw-100">
          <Row className="justify-content-md-center">
            <Col xs lg="12">
              <div className="page-header">
                <Container className="mw-100">
                  {editUser.role ? (
                    <>
                      <Row>
                        <Col className="text-start">
                          <BreadcrumbList
                            isFromOrgDetails={true}
                            list={updatedBreadList}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xs={12}
                          className={`page-title text-start ${
                            process.env.REACT_APP_PROJECT ===
                            AppDefaults.PROJECT_MEGATRON
                              ? 'org-details-black'
                              : 'org-details'
                          }`}
                        >
                          {editUser?.orgName}
                          {getCustomerOrgPolicies()?.update_cust_org && (
                            <HiOutlinePencil
                              style={{ marginLeft: 15, cursor: 'pointer' }}
                              onClick={() =>
                                navigate(
                                  `/customers/editOrganization.html?orgId=${orgId}`
                                )
                              }
                              size={16}
                              role="button"
                            />
                          )}
                          <p
                            className={`page-title-note ${
                              process.env.REACT_APP_PROJECT ===
                              AppDefaults.PROJECT_MEGATRON
                                ? 'org-details-black'
                                : 'org-details'
                            }`}
                          >
                            {constants.ORG_DASHBOARD_ID}
                          </p>
                          <div className="d-flex">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                !hoverTooltipOrgIDVisible ? (
                                  <Tooltip>
                                    {constants.TEXT_COPIED_ORGID_TITLE}
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <button
                                type="button"
                                className="'org-details btn border-0 position-relative py-1 ps-0 px-2'"
                              >
                                <small className="d-flex OrgId">
                                  <span
                                    className={`page-title-note org-details-black`}
                                    onMouseOver={() =>
                                      setHoverTooltipOrgIDVisible(false)
                                    }
                                  >
                                    {orgId}
                                  </span>
                                  <VscCopy
                                    onClick={() => copyToOrgId()}
                                    style={{ marginLeft: 10 }}
                                    size={25}
                                    role="button"
                                  />
                                </small>
                                {tooltipVisible && (
                                  <div className="custom-tooltip">
                                    {constants.DASHBOARD_TEXT_COPIED_TITLE}
                                  </div>
                                )}
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                !hoverTooltipOrgNameVisible ? (
                                  <Tooltip>
                                    {constants.TEXT_COPIED_ORGNAME_TITLE}
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <button
                                type="button"
                                className="'org-details btn border-0 position-relative py-1 ps-0 px-2'"
                              >
                                <small className="d-flex OrgId">
                                  <span
                                    className={`page-title-note org-details-black ml-2`}
                                    onMouseOver={() =>
                                      setHoverTooltipOrgNameVisible(false)
                                    }
                                  >
                                    {' '}
                                    {orgDetails?.shortName}
                                  </span>
                                  <VscCopy
                                    onClick={() => copyToOrgName()}
                                    style={{ marginLeft: 10 }}
                                    size={25}
                                    role="button"
                                  />
                                </small>
                                {tooltipOrgNameVisible && (
                                  <div className="custom-tooltip">
                                    {constants.DASHBOARD_TEXT_COPIED_TITLE}
                                  </div>
                                )}
                              </button>
                            </OverlayTrigger>
                          </div>
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xs={12}
                          className="text-end page-header-buttons"
                        >
                          {getLoggedInUserPolicies()
                            ?.enable_installer_account &&
                            selfAccountData?.orgType ===
                              AppDefaults?.ORG_TYPE_INSTALLER && (
                              <div className="installer-list-container">
                                <div className="installer-list">
                                  {installerUsers?.map((user, index) => {
                                    let profileImg = user?.image?.url;
                                    let letter = user?.name
                                      ?.match(/(\b\S)?/g)
                                      ?.join('')
                                      ?.match(/(^\S|\S$)?/g)
                                      ?.join('')
                                      ?.toUpperCase();
                                    return index < 3 ? (
                                      <div
                                        className={
                                          installerUsers?.length > 2
                                            ? 'width-11'
                                            : 'width-20'
                                        }
                                        key={user?.accountId}
                                      >
                                        <AvatarList
                                          avatarValueType={
                                            profileImg ? 'icon' : 'text'
                                          }
                                          avatarValue={
                                            profileImg ? profileImg : letter
                                          }
                                        />
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                                <PrimaryButton
                                  className="btn btn-primary-outline"
                                  type="button"
                                  width="auto"
                                  borderWidth="1.5px"
                                  hoverBorderWidth="1.5px"
                                  color={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue(
                                    process.env.REACT_APP_PROJECT ===
                                      AppDefaults.PROJECT_MEGATRON
                                      ? '--button-color-1'
                                      : '--brand_white'
                                  )}
                                  borderColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue(
                                    process.env.REACT_APP_PROJECT ===
                                      AppDefaults.PROJECT_MEGATRON
                                      ? '--button-color-1'
                                      : '--brand_white'
                                  )}
                                  hoverColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--primary_40')}
                                  backgroundColor="transparent"
                                  hoverBackgroundColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--primary_96')}
                                  hoverBorderColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue(
                                    process.env.REACT_APP_PROJECT ===
                                      AppDefaults.PROJECT_MEGATRON
                                      ? '--primary_40'
                                      : '--brand_white'
                                  )}
                                  onClick={() => {
                                    if (
                                      getLoggedInUserPolicies()
                                        ?.enable_installer_account
                                    ) {
                                      navigate(
                                        `/managetechnicians.html?orgId=${editUser?.orgId}`
                                      );
                                    }
                                  }}
                                >
                                  {
                                    constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE
                                  }
                                </PrimaryButton>
                                {/* <div>

                            </div> */}
                              </div>
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="device-detail-graph-conainer">
                            <div className="device-details w-100 mx-0">
                              <div className="header-layout">
                                <div className="devices-label">
                                  {
                                    constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE
                                  }
                                </div>
                                <div
                                  onClick={() => goToDeviceList()}
                                  className="manage-devices"
                                >
                                  {constants.ORG_DAHSBOARD_MANAGE_ALL_DEVICES}
                                </div>
                              </div>
                              <div className="camera-layout">
                                <FiVideo className="img-size" />{' '}
                              </div>
                              <div
                                className={`range-field-wrap ${
                                  (deviceListData?.filter(
                                    (device) =>
                                      device?.deviceStatus ===
                                      constants.DEVICES_CLAIMED_DEVICE_STATUS
                                  )).length > 0
                                    ? 'org-details'
                                    : 'no-devices'
                                } `}
                              >
                                <div className="range-field-container">
                                  <ReactSlider
                                    name={'valueFocus'}
                                    value={totalOnlineDevicesCount}
                                    min={0}
                                    max={totalDevicesCount}
                                    className={`range-slider ${
                                      totalOnlineDevicesCount ===
                                        totalDevicesCount && 'custom-radius'
                                    }`}
                                    trackClassName={`org-range-slider-track`}
                                  />
                                </div>
                              </div>{' '}
                              <div className="device-count-layout">
                                <div className="device-count">
                                  {totalOnlineDevicesCount}
                                </div>
                                <div className="total-device-layout">
                                  <div className="total-devices">
                                    / {totalDevicesCount}
                                  </div>
                                  <div className="active-devices">
                                    {constants.ORG_ACTIVE_DEVICES}
                                  </div>
                                </div>
                              </div>
                              <div className="seprator"></div>
                              <div className="bottom-container">
                                {deviceWithUpdatedFirmwareVersion === -1 ? (
                                  <div className="device-row-left">
                                    <SiteSpinner height="20px" width="20px" />
                                    <div className="label-name">
                                      {constants.ORG_LOADING_FIRMWARE_STATUS}
                                    </div>
                                  </div>
                                ) : deviceListData?.length === 0 ? (
                                  <div className="device-row-left">
                                    <TbDeviceComputerCameraOff className="status-icon no-devices" />
                                    <div className="label-name">
                                      {constants.ORG_NO_DEVICES_AVAILABLE}
                                    </div>
                                  </div>
                                ) : deviceWithUpdatedFirmwareVersion === 0 ? (
                                  <div className="device-row-left">
                                    <RxCheckCircled className="status-icon" />
                                    <div className="label-name">
                                      {constants.ORG_DEVICE_FIRMWARE_UPTO_DATE}
                                    </div>
                                  </div>
                                ) : (
                                  deviceWithUpdatedFirmwareVersion > 0 && (
                                    <div className="device-row-left">
                                      <PiWarningCircleLight className="status-icon update-available" />
                                      <div className="label-name">
                                        {constants.ORG_FIRMWARE_UPDATES_AVAILABLE.replace(
                                          '54',
                                          deviceWithUpdatedFirmwareVersion
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            {/* <div className="device-uptime-graph">
                              {
                                <DeviceUptime
                                  custOrgData={editUser}
                                  devices={devices}
                                />
                              }
                            </div> */}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <div className="licenses-location-account-container">
                          <div className="licenses-details">
                            <div className="header-layout">
                              <div className="header-label">
                                {constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
                              </div>
                              <div
                                onClick={() => goToLisenses()}
                                className="manage-label"
                              >
                                {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                              </div>
                            </div>
                            <div className="section-licenses">
                              <div className="label-name highlight-bg">
                                {expiringLicenses}
                              </div>
                              <div className="label-value">
                                {constants.MANAGE_ORG_LICENSES_EXPIRE_TITLE}
                              </div>
                            </div>
                            <div className="section-licenses">
                              <div className="label-name">{usedLicenses}</div>
                              <div className="label-value">
                                {constants.ORG_CLOUD_STORAGE_STATUS_ONE}
                              </div>
                            </div>
                            <div className="section-licenses">
                              <div className="label-name">
                                {availableLicenses}
                              </div>
                              <div className="label-value">
                                {constants.ORG_CLOUD_STORAGE_STATUS_TWO}
                              </div>
                            </div>

                            <div className="section-licenses">
                              <div className="label-name">
                                {expiredLicenses}
                              </div>
                              <div className="label-value">
                                {constants.ORG_DASHBOARD_LICENSES_EXPIRED_TITLE}
                              </div>
                            </div>
                          </div>
                          <div className="location-details">
                            <div className="header-layout">
                              <div className="header-label">
                                {
                                  constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE
                                }
                              </div>
                              {getCustomerOrgPolicies()?.view_location && (
                                <div
                                  onClick={() => goToLocations()}
                                  className="manage-label"
                                >
                                  {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                                </div>
                              )}
                            </div>

                            <div className="location-layout">
                              <LocationsMap
                                locationPositions={{
                                  lat: locationCoordinates?.lat,
                                  lng: locationCoordinates?.lng,
                                }}
                              />
                              <div className="section-location">
                                <div className="label-name">
                                  {locationsData?.length}
                                </div>
                                <div className="label-value">
                                  {
                                    constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accounts-details">
                            <div className="header-layout">
                              <div className="header-label">
                                {constants.TOP_HEADER_USERS_NAV_TITLE}
                              </div>
                              <div
                                className="manage-label"
                                onClick={() => goToUser()}
                              >
                                {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                              </div>
                            </div>
                            <div className="account-status-layout">
                              <div className="block-one-status">
                                <div className="label-name">
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus === 'ACTIVE' &&
                                        user?.isLocked !== true &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.ORG_LABELS_ACTIVATED}
                                </div>
                              </div>
                              <div className="block-one-status">
                                <div className="label-name">
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus === 'INACTIVE' &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.ORG_LABELS_INACTIVE}
                                </div>
                              </div>
                            </div>
                            <div className="account-status-layout">
                              <div className="block-one-status">
                                <div className="label-name highlight-bg">
                                  {' '}
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus ===
                                          'PENDING_ACTIVATION' &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value highlight-sec">
                                  {constants.ORG_LABELS_INVITED}
                                </div>
                              </div>
                              <div className="block-one-status">
                                <div className="label-name highlight-bg">
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus === 'ACTIVE' &&
                                        user?.isLocked === true &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value highlight-sec">
                                  {constants.ORG_LABLES_LOCKED}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className="role-and-partner-section">
                        <div className="roles-details">
                          <div className="header-layout">
                            <div className="header-label">
                              {constants.ORG_LABELS_ROLES}
                            </div>
                            {/* <div
                          className="manage-label"
                          onClick={() => goToUser()}
                        >
                          {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                        </div> */}
                          </div>
                          <div className="section-roles-colums">
                            <div className="section-header-roles">
                              <div className="section-roles">
                                <div className="label-name">
                                  {' '}
                                  {
                                    getCustomerOrgUsersData()?.filter(
                                      (user) =>
                                        (user.role === roles.ROLE2PORTAL ||
                                          user.role === roles.ROLE7PORTAL) &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.ORG_ADMINISTRATORS}
                                </div>
                              </div>
                              <div className="section-roles">
                                <div className="label-name">
                                  {' '}
                                  {
                                    getCustomerOrgUsersData()?.filter(
                                      (user) => user.role === roles.ROLE3PORTAL
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.TOP_HEADER_USERS_NAV_TITLE}
                                </div>
                              </div>
                              {/* <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div> */}
                            </div>
                            {/*
                        <div className="section-header-roles margin-section">
                          <div className="section-roles">
                            <div className="label-name">
                              {' '}
                              {
                                getCustomerOrgUsersData()?.filter(
                                  (user) => user.role === roles.ROLE3PORTAL
                                )?.length
                              }
                            </div>
                            <div className="label-value">
                              {constants.TOP_HEADER_USERS_NAV_TITLE}
                            </div>
                          </div>
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div>
                        </div>

                        <div className="section-header-roles margin-section">
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_OFFICE_ACCESS}
                            </div>
                          </div>
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div>
                        </div>

                        <div className="section-header-roles margin-section">
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div>
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div>
                        </div>

                        <div className="section-header-roles margin-section">
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div>
                          <div className="section-roles">
                            <div className="label-name">{0}</div>
                            <div className="label-value">
                              {constants.ORG_ROLE_CUSTOM_ONE}
                            </div>
                          </div>
                        </div> */}
                          </div>
                        </div>
                        {loggedInUserData?.orgType === 'CUSTOMER_ORG' &&
                          getCustomerOrgPolicies()?.view_org_partnerorg && (
                            <div className="partner-flow-section">
                              <div className="header-layout">
                                <div className="header-label">
                                  {
                                    constants.ORG_DASHBOARD_PARTNER_SECTION_TITLE
                                  }
                                </div>
                                {getCustomerOrgPolicies()?.send_invitation && (
                                  <div
                                    className="manage-label"
                                    onClick={() => partnerFlow()}
                                  >
                                    {Object.keys(custPartnerOrgData)?.length > 0
                                      ? constants.ORG_REMOVE_PARTNER
                                      : constants.ORG_DASHBOARD_PARTNER_INVITE}
                                  </div>
                                )}
                              </div>
                              <div
                                className={`main-section-partner ${
                                  Object.keys(custPartnerOrgData)?.length ===
                                    0 && 'no-patner'
                                }`}
                              >
                                {custPartnerOrgData?.invitationStatus &&
                                  custPartnerOrgData?.invitationStatus ===
                                    'PENDING_ACTION' && (
                                    <div className="pending-invite">
                                      <BsInfoCircle
                                        size={20}
                                        color={getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue('--warning_24')}
                                      />
                                      <div className="label-pending">
                                        {
                                          constants.ORG_PENDING_INVITATION_ACCEPTENCE
                                        }
                                      </div>
                                    </div>
                                  )}
                                {Object.keys(custPartnerOrgData)?.length > 0 ? (
                                  <div className="section-partner no-invite-section mb-1">
                                    <div className="section-left-patner-details ">
                                      <div
                                        className={`label-name ${
                                          !custPartnerOrgData?.orgName &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_ORG_NAME_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.orgName}
                                      </div>
                                      <div
                                        className={`label-name other-label ${
                                          !custPartnerOrgData?.addressLine1 &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_ADDRESS_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.addressLine1}
                                      </div>
                                      <div
                                        className={`label-name other-label ${
                                          !custPartnerOrgData?.phoneNumber &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_PHONE_NUMBER_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.phoneNumber}
                                      </div>
                                      <div
                                        className={`label-name other-label ${
                                          !custPartnerOrgData?.contactEmail &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_EMAIL_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.contactEmail}
                                      </div>
                                    </div>
                                    <div className="section-technicians">
                                      <div className="label-name">
                                        {
                                          constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE
                                        }
                                      </div>
                                      {installerUsers?.length > 0 ? (
                                        <ScrollbarWrapper height={200}>
                                          {installerUsers?.map(
                                            (user, index) => {
                                              let profileImg = user?.image?.url;
                                              let letter = user?.name
                                                ?.match(/(\b\S)?/g)
                                                ?.join('')
                                                ?.match(/(^\S|\S$)?/g)
                                                ?.join('')
                                                ?.toUpperCase();
                                              return index < 3 ? (
                                                <div key={user?.accountId}>
                                                  <AvatarList
                                                    avatarValueType={
                                                      profileImg
                                                        ? 'icon'
                                                        : 'text'
                                                    }
                                                    avatarValue={
                                                      profileImg
                                                        ? profileImg
                                                        : letter
                                                    }
                                                    title={`${user?.firstName} ${user?.lastName}`}
                                                  />
                                                </div>
                                              ) : null;
                                            }
                                          )}
                                        </ScrollbarWrapper>
                                      ) : (
                                        <div className="no-technician">
                                          <div className="bg-no-tech">
                                            <img
                                              className="img-no-tech"
                                              src={noTechAssign}
                                            ></img>
                                          </div>
                                          <div className="label-no-tech">
                                            {
                                              constants.NO_ASSIGN_TECHNICIANS_FOUND_TITLE
                                            }
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <Row>
                                    <Col className="userIcon">
                                      <HiUserCircle
                                        size={48}
                                        fill={getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue('--greyscale_80')}
                                      />
                                      <div className="noUser">
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_NOT_FOUND
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col className="text-start">
                          <BreadcrumbList
                            isFromOrgDetails={true}
                            list={breadList}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xs={12}
                          className={`page-title text-start ${
                            process.env.REACT_APP_PROJECT ===
                            AppDefaults.PROJECT_MEGATRON
                              ? 'org-details-black'
                              : 'org-details'
                          }`}
                        >
                          {editUser?.orgName}
                          <p
                            className={`page-title-note org-ids mt-2 ${
                              process.env.REACT_APP_PROJECT ===
                              AppDefaults.PROJECT_MEGATRON
                                ? 'org-details-black'
                                : 'org-details'
                            }`}
                          >
                            {editUser.addressLine1}
                          </p>
                          <p
                            className={`page-title-note org-ids mt-1 ${
                              process.env.REACT_APP_PROJECT ===
                              AppDefaults.PROJECT_MEGATRON
                                ? 'org-details-black'
                                : 'org-details'
                            }`}
                          >
                            {editUser.city ? editUser.city + ',' : ''}{' '}
                            {editUser.stateName} {editUser.zipcode}
                          </p>
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xs={12}
                          className="text-end page-header-buttons"
                        >
                          {getLoggedInUserPolicies()
                            ?.enable_installer_account &&
                            selfAccountData?.orgType ===
                              AppDefaults?.ORG_TYPE_INSTALLER && (
                              <div className="installer-list-container">
                                <div className="installer-list">
                                  {installerUsers?.map((user, index) => {
                                    let profileImg = user?.image?.url;
                                    let letter = user?.name
                                      ?.match(/(\b\S)?/g)
                                      ?.join('')
                                      ?.match(/(^\S|\S$)?/g)
                                      ?.join('')
                                      ?.toUpperCase();
                                    return index < 3 ? (
                                      <div
                                        className={
                                          installerUsers?.length > 2
                                            ? 'width-11'
                                            : 'width-20'
                                        }
                                        key={`${user?.accountId}${index}`}
                                      >
                                        <AvatarList
                                          avatarValueType={
                                            profileImg ? 'icon' : 'text'
                                          }
                                          avatarValue={
                                            profileImg ? profileImg : letter
                                          }
                                        />
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                                <PrimaryButton
                                  className="btn btn-primary-outline"
                                  type="button"
                                  width="auto"
                                  borderWidth="1.5px"
                                  hoverBorderWidth="1.5px"
                                  color={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue(
                                    process.env.REACT_APP_PROJECT ===
                                      AppDefaults.PROJECT_MEGATRON
                                      ? '--button-color-1'
                                      : '--brand_white'
                                  )}
                                  borderColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue(
                                    process.env.REACT_APP_PROJECT ===
                                      AppDefaults.PROJECT_MEGATRON
                                      ? '--button-color-1'
                                      : '--brand_white'
                                  )}
                                  hoverColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--primary_40')}
                                  backgroundColor="transparent"
                                  hoverBackgroundColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--primary_96')}
                                  hoverBorderColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue(
                                    process.env.REACT_APP_PROJECT ===
                                      AppDefaults.PROJECT_MEGATRON
                                      ? '--primary_40'
                                      : '--brand_white'
                                  )}
                                  onClick={() => {
                                    if (
                                      getLoggedInUserPolicies()
                                        ?.enable_installer_account
                                    ) {
                                      navigate(
                                        `/managetechnicians.html?orgId=${editUser?.orgId}`
                                      );
                                    }
                                  }}
                                >
                                  {
                                    constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE
                                  }
                                </PrimaryButton>
                              </div>
                            )}
                        </Col>
                      </Row>
                      <div className="info-notification">
                        <PiInfo size={20} className="smiley-icon" />
                        {constants.ORD_DASHBOARD_MESSAGE}
                      </div>

                      <div className="admin-container">
                        <div className="admin-title">
                          {constants.ORG_DASHBOARD_ADMIN}
                        </div>
                        <>
                          {orgAdmins.length && !showLoader ? (
                            orgAdmins?.map((admin, index) => {
                              return (
                                <>
                                  <div className="admin-details">
                                    <div className="initials">
                                      {admin.firstName.charAt(0)}
                                      {admin.lastName.charAt(0)}
                                    </div>
                                    <div className="info-cintainer">
                                      <div className="name">
                                        {admin.firstName} {admin.lastName}
                                      </div>
                                      <div className="info">{admin.email}</div>
                                      <div className="info">
                                        {admin.phoneNumber}
                                      </div>
                                    </div>
                                  </div>
                                  {index !== orgAdmins.length - 1 ? (
                                    <div className="seperator"></div>
                                  ) : null}
                                </>
                              );
                            })
                          ) : showLoader ? (
                            <SiteSpinner height="50px" width="50px" />
                          ) : null}
                        </>
                      </div>
                    </>
                  )}
                </Container>
              </div>
            </Col>
          </Row>
        </Container>

        <SiteModal
          modalTitle={constants.EDIT_ORG_PAGE_TITLE}
          showModal={showEditOrgModal}
          hideModal={() => setShowEditOrgModal(false)}
        >
          <EditOrganization
            editUser={editUser}
            timezone={custPartnerOrgData?.timezone}
            orgContact={orgContact}
            onChangeEdit={onChangeEdit}
            reloadData={() => fetchOrganizations()}
            hideModal={() => setShowEditOrgModal(false)}
            showRemoveOrgModal={() => setShowRemoveOrgModal(true)}
            hideRemoveOrgModal={() => setShowRemoveOrgModal(false)}
          />
        </SiteModal>

        <SiteModal
          modalTitle={`Remove ${editUser.orgName}`}
          showModal={showRemoveOrgModal}
          hideModal={() => setShowRemoveOrgModal(false)}
          classes="remove-org-modal"
        >
          <RemoveOrganization
            editUser={editUser}
            timezone={custPartnerOrgData?.timezone}
            hideRemoveOrgModal={() => setShowRemoveOrgModal(false)}
          />
        </SiteModal>
      </div>
      {/* TODO : use later */}
      <SiteModal
        modalTitle={`${constants.ORG_REMOVE_PARTNER_MODAL_TITLE} ${custPartnerOrgData?.orgName} ?`}
        showModal={showRemovePartnerModal}
        hideModal={() => setShowRemovePartnerModal(false)}
      >
        {constants.ORG_REMOVE_PARTNER_DESCRIPTION}
        <div className="remove-btn-conatiner">
          <PrimaryButton
            className="btn btn-remove"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_64'
            )}
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_48')}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            backgroundColor="transparent"
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_80')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            onClick={() => deletePartner()}
          >
            {constants.ORG_REMOVE_PARTNER}
          </PrimaryButton>

          <PrimaryButton
            className="btn btn-primary"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
            onClick={() => {
              setShowRemovePartnerModal(false);
            }}
          >
            {constants.CAMERAS_LIVE_ACTION_BUTTONS_CANCEL_LABEL}
          </PrimaryButton>
        </div>
      </SiteModal>

      <SiteModal
        modalTitle={
          invitationSend
            ? constants.ORG_INVITE_SENT
            : `${constants.ORG_INVITE_A_PARTNER}`
        }
        showModal={invitePartnerModal}
        hideModal={() => (invitationSend ? getPartnerOrg() : hideInviteModal())}
        classes={invitationSend && 'send-invitation-partner remove-org-modal'}
      >
        <div className={`sub-title-modal ${invitationSend && 'sent-invite'}`}>
          {invitationSend
            ? constants.ORG_INVITE_SENT_MESSAGE.replace('$email', emailInput)
            : constants.ORG_INVITE_PARTNER_MESSAGE}
        </div>
        {invitationSend ? (
          <div>
            <div className="org-remove-invite-status">
              <div className="device-claimed-status">
                <div className="claimed-view">
                  <img className="image-tick" src={icontick}></img>
                </div>
              </div>
            </div>
            <PrimaryButton
              className="btn mt-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_72')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                setInvitePartnerModal(false);
                setInvitationSend(false);
                getPartnerOrg();
              }}
            >
              {constants.ORG_BACK_TO_SETTING}
            </PrimaryButton>
          </div>
        ) : (
          <div>
            <div className="textbox role-input-group-text">
              <input
                value={emailInput}
                onChange={(e) => onHandleChange(e)}
                onKeyDown={(e) => onKeyDownChange(e)}
                style={{ width: '100%', marginTop: 10 }}
                className={`form-control require shadow-none${
                  emailInput ? 'has-value' : ''
                } ${emailError && 'email-error'}`}
                placeholder={constants.EMAIL_TABLE_HEADER}
                autoComplete="off"
                beforeinputicon=""
                afterinputicon=""
              />
              {emailInput === '' && (
                <span className="placeholder-wrapper org-invite required is-invalid"></span>
              )}
            </div>
            {emailError && (
              <div className="partner-email-not-verified">
                <PiWarningCircleLight
                  size={25}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_112')}
                />
                <div className="email-error-message">{emailErrorMessage}</div>
              </div>
            )}
            <div className="org-remove-invite-status mt-4 ">
              <PrimaryButton
                className="btn btn-primary"
                type="button"
                width="100%"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                disabled={!validateEmail(emailInput)}
                onClick={() => {
                  sendInvite();
                }}
              >
                {constants.ORG_SEND_PARTNER_INVITE}
              </PrimaryButton>
            </div>
          </div>
        )}
      </SiteModal>
    </div>
  );
}

export default OrganizationDetails;
