import { useEffect, useMemo, useState, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import moment from 'moment';
import { HiOutlineUser } from "react-icons/hi2";
import {
  Avatar,
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteSpinner
} from '../../components/common';
import PageWrapper from '../PageWrapper';
import { constants, Utils, roles } from '../../helpers';
import { UsersListTable } from '../users';
import { EditUser, NewUser } from '.';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { FaCircle } from 'react-icons/fa';
import { RxPlus } from 'react-icons/rx';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { success } from '../../assets/images';
import useCustomRoles from '../../store/CustomRolesStore';
import arrowLeft from "../../assets/images/icons/Arrowleft.svg";
import dotshorizontal from "../../assets/images/icons/Dotshorizontal.svg";

// import {
//   getSelectedOrganization,
//  }  from '../../store/reducers/AccountReducer'
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { getSelectedOrganization } from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const ManageUsers = () => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [userStatus,setUserStatus] = useState(false)
  // const getCustomerOrgData = useOrganizations((state) => state.getCustomerOrgData);

  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  // const orgData = useSelector(getSelectedOrganization);
  const [orgData, setOrgData] = useState();
  //const orgData = customerOrgData?.find((org) => org.orgId === orgId);
  const loggedInUser = getLoggedInUserData();
  // const orgNameBread = orgData?.orgName;
  const [orgNameBread, setOrgNameBread] = useState('');

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgId, orgNameBread)?.url,
      title: Utils.CheckLoggedInUserRole(orgId, orgNameBread)?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}&fromPush=true`,
      title: orgNameBread,
    },
    {
      url: `/users/manage.html?orgId=${orgId}`,
      title: constants.ORG_DASHBOARD_USER_SECTION_TITLE,
    },
  ];

  const mobileBreadList = [
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}&fromPush=true`,
    },
  ];
  // const policyData = orgData?.policies?.find(
  //   (item) => item.policyName === 'update_other_account'
  // );
  // const policyEditStatus = policyData ? true : false;
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  // TODO: delete later
  // const tokenParsed = keycloak.tokenParsed;
  const [tokenData, setTokenData] = useState(null);
  const vmsToken = localStorage.getItem('vmsAuthToken');
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [customerUsersData, setCustomerUsers] = useState([]);
  const [showUnlockSuccessModal, setShowUnlockSuccessModal] = useState(false);
  const [fetchCustomerOrgUserStatus, setFetchCustomerOrgUserStatus] =
    useState(true);
  const [modalTitleData, setModalTitleData] = useState(
    constants.NEW_CUSTOMER_TITLE
  );
  const [editModalTitleData, setEditModalTitleData] = useState(
    constants.EDIT_CUSTOMER_TITLE
  );
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [editAccountId, setEditAccountId] = useState(false);
  const [editAccountData, setEditAccountData] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuperAdminDropDown, setShowSuperAdminDropDown] = useState(false);
  const navigate = useNavigate();

  //=== Store get/set actions
  const getCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsers
  );
  const customerOrgUsersData = useCustomerOrgUsers(
    (state) => state.customerOrgUsersData
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations
  );
  const getEditCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getEditCustomerOrgUsersData
  );
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  // const getCustomerOrgDataValues = getCustomerOrgData()[0]?.policies;
  // const getPartnerOrgDataValues = getCustomerOrgPolicies()
  const { getCustomRoleList, roleList } = useCustomRoles();
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  let [custOrgPolicies, setCustOrgPolicies] = useState([]);
  const [policyEditStatus, setPolicyEditStatus] = useState(false);

  useEffect(() => {
    const getOrgData = async () => {
      const orgs = await getCustomerOrgData();
      setCustOrgPolicies(orgs?.[0]?.policies || []);
    };
    getOrgData();
  }, [orgId]);

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    const status = org?.policies?.find(
      (item) => item.policyName === 'update_other_account'
    );
    setOrgData(org || {});
    setPolicyEditStatus(status);
    setOrgNameBread(org?.orgName);
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    if(orgData && getCustomerOrgPolicies().length <= 0) {
      let policies = orgData?.policies;
      let updatedPoliciesData = Utils.mapUserPolicies(DefaultPolicies, policies);
      setCustomerOrgPolicies(updatedPoliciesData);
    }    
  }, [getCustomerOrgPolicies(), orgData]);

  const filterCustomerUsers = () => {
    let resultData = getCustomerOrgUsersData();
    if (resultData?.length >= 1) {
      const list =resultData?.filter(
        (user) =>
          (user.role === roles.ROLE2PORTAL || user.role === roles.ROLE3PORTAL
            || user.role === roles.ROLE7PORTAL) &&
          loggedInUser?.accountId !== user?.accountId
      )
      setCustomerUsers(
        resultData?.filter(
          (user) =>
            (user.role === roles.ROLE2PORTAL || user.role === roles.ROLE3PORTAL
              || user.role === roles.ROLE7PORTAL) &&
            loggedInUser?.accountId !== user?.accountId
        )
      ); //=== Update state to load data
      setUserStatus(!list?.length ? true : false);
    }
  };

  //=== Filter and store customer organizations user data to the state
  useEffect(() => {
    filterCustomerUsers();
  }, [customerOrgUsersData]);

  const { getUserCustomer } = useCustomerOrgLocations();

  useEffect(() => {
    getCustomRoleList(`partner/orgs/${orgId}/roles`).then((res) => {
      setTimeout(() => {
        let resultData = getCustomerOrgUsersData();
        const isRoleSuperAdmin = resultData.filter(item => item?.role === roles.ROLE7PORTAL)?.length
        if (isRoleSuperAdmin <= 0) {
          const roleOption = [];
          let obj = {
            label: constants.SUPER_ADMIN_ROLE_LABEL,
            value: roles.ROLE7PORTAL
          }
          roleOption.push(obj)
          setRoleOptions(roleOption);
          setShowLoader(false);
          return;
        }
      }, 500)

      if (res?.length > 0) {
        const roleOption = [];
        res.forEach((role) => {
          if (role.roleId !== roles.ROLE1PORTAL && role.roleId !== roles.ROLE7PORTAL ) {
            if(role.roleName === 'cust_org_admin'){
              if(shouldRenderAdminstrator){
                let obj = {
                  label: role.roleName === 'cust_org_admin' ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL : role.roleName === 'cust_org_user' ? constants.NORMAL_USER_ROLE_LABEL : role.roleName,
                  value: role.roleId
                }
                roleOption.push(obj)
              }
            }
            else {
              let obj = {
                label: role.roleName === 'cust_org_user' ? constants.NORMAL_USER_ROLE_LABEL : role.roleName,
                value: role.roleId
              }
              roleOption.push(obj)
            }
          }
         })
        setRoleOptions(roleOption);
        setShowLoader(false);
      }else{
        setShowLoader(false);
      }
    });
  }, [getCustomerOrgUsersData()])



  const fetchCustomersData = async (token) => {
    setShowLoader(true);
    if (token) {
      setFetchCustomerOrgUserStatus(false);
      await getCustomerOrgUsers(`partner/orgs/${orgId}/accounts`);
      // if (cutomOrgData?.status === "success") {
      //  let resultData = getCustomerOrgUsersData();
      //  if(resultData?.length <= 0){
      //    setUserStatus(true)
      //  }
      // }
 
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
    }
  };

  useEffect(() => {
    if (
      fetchCustomerOrgUserStatus &&
      vmsToken
    ) {
      // TODO: delete later
      // setTokenData(token);
      fetchCustomersData(vmsToken); //=== Fetch the partner - customer user accounts list
    }
  }, [vmsToken]);

  const columnsData = useMemo(
    () => [
      {
        Header: constants.NAME_TABLE_HEADER,
        accessor: 'name',
        maxWidth: 200,
        minWidth: 50,
        width: 180,
        Cell: (props) => {
          let name = props.value;
          let allRecords = props?.data;
          let ifDataExists = allRecords?.find((row) => row?.accountId === props?.row?.original?.accountId);
          let letter = name
            .match(/(\b\S)?/g)
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
            .toUpperCase();
          return (
            <span className="userName">
              <Avatar
                valueType={ifDataExists?.image?.url ? 'icon' : 'text'}
                value={
                  ifDataExists?.image?.url ? ifDataExists?.image?.url : letter
                }
                size="medium"
                avatarStyle="roundedCircle"
              />
              {name}
            </span>
          );
        },
        sortType: 'basic',
      },
      {
        Header: constants.EMAIL_TABLE_HEADER,
        accessor: 'email',
        sortType: 'basic',
      },
      {
        Header: constants.ROLE_TABLE_HEADER,
        accessor: 'role',
        maxWidth: 100,
        minWidth: 50,
        width: 70,
        Cell: (props) => {
          const roleValue = props.value;
          return roleValue === roles.ROLE7PORTAL
          ? constants.SUPER_ADMIN_ROLE_LABEL
          : roleValue === roles.ROLE2PORTAL
          ? constants.ADMIN_ROLE_LABEL
          : constants.NORMAL_USER_ROLE_LABEL;
        },
        sortType: 'basic',
      },
      {
        Header: constants.INCIDENTS_DETAILS_STATUS_TITLE,
        accessor: 'accountStatus',
        maxWidth: 150,
        minWidth: 50,
        width: 130,
        Cell: (props) => {
          const activeCheck = props.value;
          const isLocked = props.row.original.isLocked;
          return (
            <span className="activeIcon">
              {activeCheck === 'ACTIVE' && isLocked ? (
                <FaCircle size={10} className="status-icon-offline" />
              ) : activeCheck === 'ACTIVE' ? (
                <FaCircle size={10} className="status-icon-active" />
              ) : activeCheck === 'PENDING_ACTIVATION' ? (
                <FaCircle size={10} className="status-icon-invited" />
              ) : (
                <FaCircle size={10} className="status-icon-notactive" />
              )}
              {activeCheck === 'ACTIVE' && isLocked
                ? constants.LOCKED_ACCOUNT_TEST
                : activeCheck === 'ACTIVE'
                ? constants.ACTIVE_USER_STATUS_LABEL
                : activeCheck === 'PENDING_ACTIVATION'
                ? constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE
                : constants.DEVICES_RETURN_DEACTIVATED_STATUS}
            </span>
          );
        },
        sortType: 'basic',
      },
      {
        Header: constants.LAST_ACTIVE_TABLE_HEADER,
        accessor: 'activeDate',
        maxWidth: 200,
        minWidth: 65,
        width: 170,
        Cell: (props) => {
          let retVal = '-';
          if (props.value !== 0) {
            retVal = moment(
              new Date(moment.unix(+props.value / 1000).toDate())
            ).format('D MMM YYYY hh:mm a');
          }
          return <span>{retVal}</span>;
        },
        sortType: 'basic',
      },
      {
        Header: constants.LAST_UPDATED_TABLE_HEADER,
        accessor: 'updatedDate',
        maxWidth: 250,
        minWidth: 65,
        width: 170,
        Cell: (props) => {
          let retVal = '-';
          if (props.value !== 0) {
            retVal = moment(
              new Date(moment.unix(+props.value / 1000).toDate())
            ).format('D MMM YYYY hh:mm a');
          }
          return <span>{retVal}</span>;
        },
        sortType: 'basic',
      },
    ],
    []
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'name',
        desc: true,
      },
      {
        id: 'email',
        desc: true,
      },
      {
        id: 'role',
        desc: true,
      },
      {
        id: 'accountStatus',
        desc: true,
      },
      {
        id: 'activeDate',
        desc: true,
      },
      {
        id: 'updatedDate',
        desc: true,
      },
    ],
    []
  );

  const handleModalTitleUpdate = (data) => {
    setModalTitleData(data);
  };

  const handleEditModalTitleUpdate = (data) => {
    setEditModalTitleData(data);
  };

  const handleEditUser = async (userId) => {
    const ediUserData = await getEditCustomerOrgUsersData(userId);
    if(ediUserData?.role !== roles?.ROLE7PORTAL){
      setEditAccountData(ediUserData);
      setEditAccountId(userId);
      setShowEditOrgModal(true);
    }
  };

  const shouldRenderUsersListTable =
    custOrgPolicies &&
    custOrgPolicies?.some((item) => item.policyName === 'view_account') && customerUsersData.length > 0;

  const shouldRenderAdminstrator = getCustomerOrgPolicies()?.create_customer_admin_role;

  return (
    <div className="App">
      <Header
        showHeader={true}
        breadcrumbData={breadList}
        className="desktop"
      />
      <div className="breadcrumbsResponsive mobile">
        <div className="text-start me-auto navbar-brand arrow">
          <img src={arrowLeft} alt=""></img>
          <spam
            className="backText"
            onClick={() => navigate(mobileBreadList[0]?.url)}
          >
            {constants.BREADCRUMB_BACK}
          </spam>
        </div>
        <Nav>
          <NavDropdown
            className="mobile"
            title={
              <div>
                <img className="dots" src={dotshorizontal} alt=""></img>
              </div>
            }
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item
              href="#"
              onClick={() => {
                setShowAddOrgModal(true);
              }}
            >
              <div>{constants.MANAGE_ORG_ADD_USER_BUTTON_TITLE}</div>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </div>
      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className='mw-100'>
            <Row>
              <Col className="text-start">
                <BreadcrumbList
                  list={Utils.CheckBreadcrumbForUserRole(breadList)}
                />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {constants.ORG_DASHBOARD_USER_SECTION_TITLE}
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="end-align"
              >
                {custOrgPolicies &&
                  custOrgPolicies?.some(
                    (item) => item.policyName === "create_account"
                  ) && (
                    <PrimaryButton
                      className="d-md-block d-none primary-btn-class btn-primary"
                      type="button"
                      width="auto"
                      height="44px"
                      fontSize="14px"
                      onClick={() => {
                        setShowAddOrgModal(true);
                      }}
                    >
                     <RxPlus
                    className="plus-icon-btn"
                    strokeWidth={"1.5px"}
                    size={13.33}
                     />
                      {constants.MANAGE_ORG_ADD_USER_BUTTON_TITLE}
                    </PrimaryButton>
                  )}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="list-block">
          <Container className="h-100 mw-100">
            <div>
            {showLoader ? (
              <div className="no-data-available">
                <div className='position-absolute l-48 t-52'>
                      <SiteSpinner height="50px" width="50px" />
                      <div className='mt-2 text-dark'>{constants.LOADING}</div>
                </div>
              </div>
            ) : (
              shouldRenderUsersListTable ? (
                <UsersListTable
                  columns={columnsData}
                  defaultSortByData={sortingData}
                  data={customerUsersData?.filter(item => item?.role !== roles.ROLE6PORTAL)}
                  handleClickEditUser={handleEditUser}
                />
              ) : userStatus &&  (
                <div className="no-devices-container">
                  <div className="image-wrapper">
                    <HiOutlineUser size={32} />
                  </div>
                  <div>{constants.NO_USERS_FOUND_TITLE}</div>
                </div>
              )
            )}
            </div>
          </Container>
        </div>
      </PageWrapper>
      <SiteModal
        modalTitle={modalTitleData}
        showModal={showAddOrgModal}
        hideModal={() => setShowAddOrgModal(false)}
      >
        <NewUser
          reloadData={() => {
            fetchCustomersData(token);
          }}
          hideModal={() => setShowAddOrgModal(false)}
          modalTitle={constants.NEW_CUSTOMER_TITLE}
          handleModalTitle={(data) => {
            handleModalTitleUpdate(data);
          }}
          roleOption={roleOptions}
        />
      </SiteModal>

      <SiteModal
        modalTitle={editModalTitleData}
        showModal={showEditOrgModal}
        hideModal={() => setShowEditOrgModal(false)}
        classes="edit-customer-user-modal"
      >
        <EditUser
          policyEditStatus={policyEditStatus}
          accountId={editAccountId}
          accountData={editAccountData}
          reloadData={() => {
            fetchCustomersData(token);
          }}
          hideModal={() => setShowEditOrgModal(false)}
          modalTitle={constants.EDIT_CUSTOMER_TITLE}
          handleModalTitle={(data) => {
            handleEditModalTitleUpdate(data);
          }}
          showUnlockModal={() => setShowUnlockSuccessModal(true)}
          roleOption={roleOptions}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.ACCOUNT_UNLOCKED}
        showModal={showUnlockSuccessModal}
        hideModal={() => setShowUnlockSuccessModal()}
        classes="unlock-success-modal"
      >
        <div className="d-flex flex-column align-items-center gap-4 text-center">
          <Avatar
            value={success}
            valueType="icon"
            size="extraLarge"
            style="roundedCircle"
          />
          <div className="w-100">
            <PrimaryButton
              className="btn btn-primary w-100"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="100%"
              onClick={() => setShowUnlockSuccessModal(false)}
            >
              {constants.BACK_TO_USERS_BUTTON_TEXT}
            </PrimaryButton>

            <PrimaryButton
              className="btn btn-outline-danger w-100 mt-2"
              type="button"
              height="44px"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--brand_white")}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--primary_40")}
              onClick={() => {
                setShowUnlockSuccessModal(false);
                handleEditUser(editAccountId);
              }}
            >
              {`Edit ${editAccountData?.firstName} ${editAccountData?.lastName} Profile`}
            </PrimaryButton>
          </div>
        </div>
      </SiteModal>
    </div>
  );
};

export default ManageUsers;
